import { call, select, put } from 'redux-saga/effects';
import { push, replace } from 'connected-react-router';
import { ToastrEmitter } from 'react-redux-toastr';
import get from 'lodash/get';
import axios, { AxiosError, AxiosPromise } from 'axios';

import i18n from '../../i18n';
import { refreshTokenRequest } from 'actions/auth';
import { REFRESH_TOKEN_FAILED, REFRESH_TOKEN_SUCCEEDED } from 'actionTypes';
import { refreshPromiseSelector } from 'selectors/auth';
import { getErrorMessage } from 'utils';
import { IRefreshSagaParams } from 'types/common';

function* resetUserSaga(e: AxiosError) {
  const status = get(e, `response.status`);
  if (status === 401) {
    yield put({ type: REFRESH_TOKEN_FAILED });
    yield put(push(`/login`));
    yield localStorage.removeItem(`token`);
  }
}

export interface IGetRefreshSagaParams {
  refreshToken: () => AxiosPromise;
  toastr: ToastrEmitter;
}

// Todo: get rid of @ts-ignore
const getRefreshSaga = ({ refreshToken, toastr }: IGetRefreshSagaParams) =>
  function* refreshSaga({
    request,
    onError,
    onSuccess,
    onFinally,
    redirectWhenNoPermissions = false,
    callErrorWhenNoPermissions = false,
    showToastrWhenNoPermissions = true,
  }: IRefreshSagaParams) {
    if (!onError) {
      onError = (err) => {
        toastr.error(i18n.t(`common.error`), getErrorMessage(err as AxiosError) as string);
      };
    }

    // @ts-ignore
    let refreshPromise = yield select(refreshPromiseSelector);
    if (!refreshPromise) {
      let resp;
      try {
        // @ts-ignore
        resp = yield call(request);
        if (resp?.status !== 401 && onSuccess) {
          yield call(onSuccess, resp);
        }
      } catch (e) {
        if (axios.isAxiosError(e)) {
          const status = get(e, `response.status`);
          if (status === 403) {
            if (redirectWhenNoPermissions) {
              yield put(replace(`/no-permissions`));
            } else if (showToastrWhenNoPermissions) {
              toastr.error(i18n.t(`common.error`), i18n.t(`common.noRights`));
            }
            if (callErrorWhenNoPermissions) {
              yield call(onError, e);
            }
          } else if (status !== 401 && onError) {
            yield call(onError, e);
          }
        }
      } finally {
        if (onFinally) {
          yield call(onFinally);
        }
      }
      if (resp && resp.status === 401) {
        // @ts-ignore
        refreshPromise = yield select(refreshPromiseSelector);
        if (!refreshPromise) {
          try {
            const promise = refreshToken();
            yield put(refreshTokenRequest(promise));
            const { data, status } = yield promise;
            if (status === 401) {
              throw new Error(`Token refreshing has been failed`);
            }
            yield put({ type: REFRESH_TOKEN_SUCCEEDED, payload: data });
            // @ts-ignore
            const resp = yield call(request);
            if (onSuccess) {
              yield call(onSuccess, resp);
            }
            yield localStorage.setItem(`token`, data.token);
          } catch (e) {
            if (axios.isAxiosError(e)) {
              if (onError) {
                yield call(onError, e);
              }
              yield resetUserSaga(e);
            }
          } finally {
            if (onFinally) {
              yield call(onFinally);
            }
          }
        }
      }
    }

    if (refreshPromise) {
      try {
        yield refreshPromise;
        // @ts-ignore
        const resp = yield call(request);
        if (onSuccess) {
          yield call(onSuccess, resp);
        }
      } catch (e) {
        if (axios.isAxiosError(e)) {
          if (onError) {
            yield call(onError, e);
          }
          yield resetUserSaga(e);
        }
      } finally {
        if (onFinally) {
          yield call(onFinally);
        }
      }
    }
  };

export default getRefreshSaga;
