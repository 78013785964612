import React from 'react';

import Tooltip from 'components/base/Tooltip';

export interface IWalletImgProps {
  title?: string;
  src?: string;
  className?: string;
}

const WalletImg = ({ title, src, className }: IWalletImgProps) => {
  return (
    <Tooltip insideContent={title}>
      <img src={src} alt={title} className={className || `wallet_type`} />
    </Tooltip>
  );
};

WalletImg.defaultProps = {
  title: ``,
  className: ``,
  src: ``,
};

export default WalletImg;
