import { RefObject, useRef, useState } from 'react';

import { SCROLL_PAGINATION_LIMIT } from 'utils/constants';
import useWidgetScrollPagination from 'hooks/widgets/useWidgetScrollPagination';
import useList from 'hooks/widgets/useList';
import { IWidgetActionCreators, IWidgetsState } from 'types/widgets';
import { Dispatch } from 'redux';

export interface IModalPaginationTableParams<Item = any> {
  actionCreators: IWidgetActionCreators;
  widgetState: IWidgetsState;
  dispatch: Dispatch;

  queryParams?: Record<string, unknown>;
}

export interface IModalPaginationTableResult<Item = any> {
  items: Item[] | null;
  modalContentRef: RefObject<any>;
  listLoading: boolean;
}

const useModalPaginationTable = <Item = any>({
  queryParams: requiredQueryParams,
  widgetState,
  actionCreators,
  dispatch,
}: IModalPaginationTableParams<Item>): IModalPaginationTableResult<Item> => {
  const modalContentRef = useRef(null);

  const [queryParams] = useState({
    ...requiredQueryParams,
    limit: SCROLL_PAGINATION_LIMIT,
  });

  const { items, listLoading } = widgetState;

  useList({
    actionCreators,
    items,
    listLoading,
    queryParams,
    dispatch,
  });

  useWidgetScrollPagination({
    widgetState,
    queryParams,
    loadList: (...params) => dispatch(actionCreators.getList(...params)),
    ref: modalContentRef,
  });

  return {
    items,
    modalContentRef,
    listLoading,
  };
};

export default useModalPaginationTable;
