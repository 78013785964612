import React, { useState, useCallback, FunctionComponent, ReactNode } from 'react';
import get from 'lodash/get';
import map from 'lodash/map';
import toNumber from 'lodash/toNumber';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import { push } from 'connected-react-router';
import classNames from 'classnames';
import { Dispatch } from 'redux';

import { updateSearchParams } from 'utils';

interface ITabPanel {
  children: ReactNode;
  value: number;
  index: number;
}

const TabPanel = ({ children, value, index }: ITabPanel) => (
  <div id={`tabpanel-${index}`}>{value === index && <Box>{children}</Box>}</div>
);

const indexAtTab = (index: number) => ({
  id: `tab-${index}`,
});

interface ITab {
  title: string;
  hasAccess?: boolean;
  component: FunctionComponent<any>;
}

interface IProps {
  search: { [key: string]: string | number };
  location: Location;
  dispatch: Dispatch;
  containerClassNames?: string;
  tabs: ITab[];
}

const TabsBar = ({ search, location, dispatch, tabs, containerClassNames }: IProps) => {
  const [value, setValue] = useState(() => {
    const tab = get(search, `tab`);

    return tab ? toNumber(tab) : 0;
  });

  const handleChange = useCallback(
    (event, newValue) => {
      setValue(newValue);

      const payload = {
        tab: newValue,
      };
      dispatch(push(updateSearchParams(location, payload)));
    },
    [location]
  );

  return (
    <div className={classNames(`block`, containerClassNames)}>
      <AppBar position="static" color="default">
        <Tabs
          TabIndicatorProps={{ style: { background: `#fff`, textTransform: `none` } }}
          value={value}
          onChange={handleChange}
          variant="scrollable"
          aria-label="simple tabs example"
        >
          {map(tabs, ({ title, hasAccess = true }, index) => hasAccess && <Tab label={title} {...indexAtTab(index)} />)}
        </Tabs>
      </AppBar>

      {map(
        tabs,
        ({ hasAccess = true, component }, index) =>
          hasAccess && (
            <TabPanel value={value} index={index}>
              {component}
            </TabPanel>
          )
      )}
    </div>
  );
};

export default TabsBar;
