import { AxiosPromise, AxiosRequestConfig } from 'axios';

export interface IFakeResponseParams {
  data?: any;
  status?: number;
  statusText?: string;
  config?: AxiosRequestConfig;
  headers?: Record<string, unknown>;
  timeout?: number;
}

const fakeResponse = ({
  data = {},
  status = 200,
  statusText = `OK`,
  config = {},
  headers = {},
  timeout = 100,
}: IFakeResponseParams = {}): AxiosPromise =>
  new Promise((resolve, reject) => {
    setTimeout(() => {
      const resp = {
        data,
        status,
        statusText,
        config,
        headers,
      };

      if (status >= 300) {
        reject({
          response: resp,
        });
      }

      resolve(resp);
    }, timeout);
  });

export default fakeResponse;
