import React, { useState, useEffect, useCallback } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import classNames from 'classnames';
import get from 'lodash/get';
import map from 'lodash/map';
import toNumber from 'lodash/toNumber';
import { WrappedFieldInputProps } from 'redux-form/lib/Field';

import { range } from 'utils';
import { IDatepickerProps } from 'types/form';
import i18n from '../../../../i18n';

export interface IDatepickerOwnProps extends IDatepickerProps {
  input: WrappedFieldInputProps;

  inputClassName?: string;
}

interface IHeaderParams {
  date: Date;
  changeYear(year: number): void;
  changeMonth(month: number): void;
  decreaseMonth(): void;
  increaseMonth(): void;
  prevMonthButtonDisabled: boolean;
  nextMonthButtonDisabled: boolean;
  decreaseYear(): void;
  increaseYear(): void;
  prevYearButtonDisabled: boolean;
  nextYearButtonDisabled: boolean;
}

const Datepicker = ({ input, minDate, maxDate = new Date(), inputClassName }: IDatepickerOwnProps) => {
  const [startDate, setStartDate] = useState(moment().toDate());

  const years = range(moment(minDate).year(), moment(maxDate).year());
  const months = moment.localeData(i18n.language).months();

  useEffect(() => {
    const date = get(input, `value`);
    if (date) {
      setStartDate(moment(date).toDate());
    }
  }, [input]);

  const onDateChange = useCallback((date: Date) => {
    setStartDate(moment(date).toDate());
    input.onChange(moment(date));
  }, []);

  const calendarHeader = ({
    date,
    changeYear,
    changeMonth,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
  }: IHeaderParams) => (
    <div className="rdrMonthAndYearWrapper">
      <button
        type="button"
        className="rdrNextPrevButton rdrPprevButton"
        onClick={decreaseMonth}
        disabled={prevMonthButtonDisabled}
      >
        <i />
      </button>
      <span className="rdrMonthAndYearPickers">
        <span className="rdrMonthPicker">
          <select
            value={months[moment(date).month()]}
            onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
          >
            {map(months, (option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </span>
        <span className="rdrYearPicker">
          <select value={moment(date).year()} onChange={({ target: { value } }) => changeYear(toNumber(value))}>
            {map(years, (option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </span>
      </span>
      <button
        type="button"
        className="rdrNextPrevButton rdrNextButton"
        onClick={increaseMonth}
        disabled={nextMonthButtonDisabled}
      >
        <i />
      </button>
    </div>
  );

  return (
    <DatePicker
      selected={startDate}
      onChange={onDateChange}
      dateFormat="dd-MM-yyyy"
      className={classNames(`form-control`, `input--datepicker`, inputClassName)}
      minDate={moment(minDate).toDate()}
      maxDate={moment(maxDate).toDate()}
      portalId="root-portal"
      renderCustomHeader={calendarHeader}
    />
  );
};

export default Datepicker;
