import React from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { MODAL_FADING_TIMEOUT } from 'utils/constants';
import { IWidgetModal } from 'types/widgets';
import { IModalData } from 'types/common';

export interface IModalComponentProps extends Omit<IModalData, `widget`> {
  modal: IWidgetModal;
}

const Modal = ({ component: Component, type, modal }: IModalComponentProps) => {
  return (
    <TransitionGroup>
      {modal && modal?.type === type && (
        <CSSTransition classNames="modal-transition" timeout={MODAL_FADING_TIMEOUT}>
          <Component />
        </CSSTransition>
      )}
    </TransitionGroup>
  );
};

export default Modal;
