import { useEffect } from 'react';

const useModalOverflow = () => {
  useEffect(() => {
    document.body.style.overflow = `hidden`;

    return () => {
      document.body.style.overflow = `auto`;
    };
  }, []);
};

export default useModalOverflow;
