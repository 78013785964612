import {
  WIDGET_LIST_REQUESTED,
  WIDGET_SET_PAGE,
  WIDGET_ITEM_REQUESTED,
  WIDGET_SET_ERROR,
  WIDGET_RESET_ERROR,
  WIDGET_LIST_ITEM_UPDATE_REQUESTED,
  WIDGET_CREATE_REQUESTED,
  WIDGET_REMOVE_REQUESTED,
  WIDGET_SET_MODAL,
  WIDGET_RESET_MODAL,
  WIDGET_LIST_RESET,
  WIDGET_ITEM_UPDATE_REQUESTED,
  WIDGET_SET_ANY_DATA,
  WIDGET_SET_ITEM,
  WIDGET_SYNC_REMOVE,
  WIDGET_REMOVE_MULTIPLE_ITEMS_REQUESTED,
  WIDGET_RESET_MODAL_ERROR,
  WIDGET_ITEM_RESET,
  WIDGET_SELECT_ITEM,
  WIDGET_UNSELECT_ITEM,
  WIDGET_RESET_SELECT_LIST,
} from 'actionTypes';

import { IWidgetActionCreators, IWidgetsMeta } from 'types/widgets';
import { IWidgetsAction } from 'types/widgets';

export const createWidgetAction = (meta: IWidgetsMeta) => (type: string) => {
  const { withoutPayloadWrap } = meta;

  return (payload?: any, extraMeta?: IWidgetsMeta): IWidgetsAction => {
    if (withoutPayloadWrap) {
      return {
        type,
        ...payload,
        meta: { ...meta, ...extraMeta },
      };
    } else {
      return {
        type,
        payload,
        meta: { ...meta, ...extraMeta },
      } as IWidgetsAction;
    }
  };
};

const widgetActionCreators = (meta: IWidgetsMeta): IWidgetActionCreators => {
  const actionCreator = createWidgetAction(meta);

  return {
    getList: actionCreator(WIDGET_LIST_REQUESTED),
    setPage: actionCreator(WIDGET_SET_PAGE),
    getItem: actionCreator(WIDGET_ITEM_REQUESTED),
    setItem: actionCreator(WIDGET_SET_ITEM),
    setError: actionCreator(WIDGET_SET_ERROR),
    resetError: actionCreator(WIDGET_RESET_ERROR),
    resetModalError: actionCreator(WIDGET_RESET_MODAL_ERROR),
    update: actionCreator(WIDGET_LIST_ITEM_UPDATE_REQUESTED),
    updateItem: actionCreator(WIDGET_ITEM_UPDATE_REQUESTED),
    create: actionCreator(WIDGET_CREATE_REQUESTED),
    remove: actionCreator(WIDGET_REMOVE_REQUESTED),
    removeSync: actionCreator(WIDGET_SYNC_REMOVE),
    removeMultipleItems: actionCreator(WIDGET_REMOVE_MULTIPLE_ITEMS_REQUESTED),
    resetSelectList: actionCreator(WIDGET_RESET_SELECT_LIST),
    selectItem: actionCreator(WIDGET_SELECT_ITEM),
    unselectItem: actionCreator(WIDGET_UNSELECT_ITEM),
    showModal: actionCreator(WIDGET_SET_MODAL),
    hideModal: actionCreator(WIDGET_RESET_MODAL),
    resetList: actionCreator(WIDGET_LIST_RESET),
    resetItem: actionCreator(WIDGET_ITEM_RESET),
    setAnyData: actionCreator(WIDGET_SET_ANY_DATA),
  };
};

export default widgetActionCreators;
