import size from 'lodash/size';
import toNumber from 'lodash/toNumber';
import split from 'lodash/split';
import toString from 'lodash/toString';

import validateMessages from 'utils/validateMessages';

const MAX_AMOUNT_DIGITS = 10;

const validateAmount = (value: string): string | undefined => {
  if (!value) {
    return undefined;
  }

  if (size(value) > 0 && !/^\d+(\.\d+)?$/.test(value)) {
    return validateMessages().invalidAmount;
  }
  if (toNumber(value) <= 0) {
    return validateMessages().minAmountShouldBeGreater(0);
  }

  const splittedAmount = split(toString(value), `.`);
  const [int, fraction] = splittedAmount;
  const invalidDigitsLengthOfAmount =
    size(splittedAmount) > 1 && (size(int) > MAX_AMOUNT_DIGITS || size(fraction) > MAX_AMOUNT_DIGITS);
  if (invalidDigitsLengthOfAmount) {
    return validateMessages().setValueRange;
  }

  return undefined;
};

export default validateAmount;
