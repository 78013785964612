import React, { MutableRefObject, useEffect, useState } from 'react';
import { DropdownList } from 'react-widgets';
// @ts-ignore
import ListOption from 'react-widgets/lib/ListOption';
import size from 'lodash/size';
import map from 'lodash/map';
import includes from 'lodash/includes';
import get from 'lodash/get';
import toLower from 'lodash/toLower';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { DropdownListProps } from 'react-widgets/lib/DropdownList';

import useDropdownOpeningToUp from 'hooks/form/useDropdownOpeningToUp';
import { ISelectData } from 'types/form';

const FILTER_FROM_SIZE = 10;

const filterCallback = (dataItem: ISelectData, str: string): boolean => {
  return includes(toLower(get(dataItem, `text`)), toLower(str));
};

interface IOptionComponentProps {
  id: string | number;
  [key: string]: any;
}

const OptionComponent = ({ id, ...props }: IOptionComponentProps) => (
  <ListOption id={`${id}_input_${get(props, `dataItem.value`)}_li`} {...props} />
);

export interface IDropdownSelectProps extends DropdownListProps {
  data?: ISelectData[];
  className?: string;
  translateKeys?: boolean;
  fieldWrapperRef?: MutableRefObject<any>;
  isModalField?: boolean;
}

const DropdownSelect = ({
  data,
  className,
  translateKeys,
  fieldWrapperRef,
  isModalField,
  id,
  ...rest
}: IDropdownSelectProps) => {
  const [t] = useTranslation();

  const [list, setList] = useState<ISelectData[]>([]);

  const withSearch = size(list) > FILTER_FROM_SIZE;

  const { doMeasure, selectRef, openUp } = useDropdownOpeningToUp({
    fieldWrapperRef,
    isModalField,
  });

  useEffect(() => {
    if (translateKeys) {
      setList(
        map(data, (item: ISelectData) => ({
          ...item,
          text: t(get(item, `text`)),
        }))
      );
    } else {
      setList(data || []);
    }
  }, [data]);

  const anyProps = {
    onBlur: (e: any) => {
      e.preventDefault();
    },
    onDrop: (e: any) => {
      e.preventDefault();
    },
    optionComponent: (props: any) => <OptionComponent {...props} id={id} />,
  };

  return (
    <div ref={selectRef}>
      <DropdownList
        {...rest}
        {...(anyProps as any)}
        data={list}
        filter={withSearch ? filterCallback : false}
        valueField="value"
        textField="text"
        id={id}
        messages={{
          open: ``,
          emptyList: t(`common.listIsEmpty`),
          emptyFilter: t(`common.filterReturnedNoResults`),
        }}
        dropUp={openUp}
        containerClassName={classNames(`form-control`, className, { 'select--with-search': withSearch })}
        onToggle={doMeasure}
      />
    </div>
  );
};

DropdownSelect.defaultProps = {
  data: [],
  className: ``,
  translateKeys: false,
  isModalField: false,
};

export default DropdownSelect;
