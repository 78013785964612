import React, {
  useEffect,
  useCallback,
  useRef,
  useState,
  ChangeEvent,
  RefObject,
  HTMLProps,
  MutableRefObject,
} from 'react';
import classNames from 'classnames';
import { Calendar, DateInputType, OnChangeProps } from 'react-date-range';
import useClickAway from 'react-use/lib/useClickAway';
import { useTranslation } from 'react-i18next';
import TimeField from 'react-simple-timefield';
import useUpdateEffect from 'react-use/lib/useUpdateEffect';
import moment from 'moment';
import get from 'lodash/get';
import { useUnmount } from 'react-use';
import { Portal } from 'react-portal';
import { WrappedFieldInputProps } from 'redux-form/lib/Field';

import { RangeDatepickerInput } from 'utils/enums';
import { getCoordsOfElem } from 'utils';
import { IRangeDatepickerProps } from 'types/form';

const CALENDAR_WIDTH = 350;
const CALENDAR_HEIGHT = 300;

type DateType = `startDate` | `endDate`;

export const displayingDateInputFormat = (date?: string | Date, time = ``, timezone?: string): string => {
  if (!date) {
    return ``;
  }

  if (time) {
    return `${moment(date).format(`DD-MM-YYYY`)} ${time}`;
  }

  if (timezone) {
    const timezoneDate = moment(date)
      .toDate()
      .toLocaleString(`en-EN`, { timeZone: timezone || `` });

    return moment(timezoneDate).format(`DD-MM-YYYY`);
  }

  return moment(date).format(`DD-MM-YYYY`);
};

const TimeInput = (props: HTMLProps<HTMLInputElement>) => (
  <input {...props} className="timepicker__input form-control" />
);

const getDateTime = (date?: string | Date, time?: string): Date => {
  date = moment(date).format(`YYYY-MM-DD`);

  return moment(`${date} ${time}`).toDate();
};

const openLeft = (ref: MutableRefObject<HTMLElement | HTMLDivElement | null>): boolean => {
  const { left = 0 } = getCoordsOfElem(ref.current);
  const datePickerCenter = get(ref, `current.offsetWidth`) / 2;

  const windowTooSmall = CALENDAR_WIDTH > window.innerWidth / 2;
  const tooCloseToRight = left + datePickerCenter + CALENDAR_WIDTH > window.innerWidth;

  return windowTooSmall || tooCloseToRight;
};

const openTop = (ref: MutableRefObject<HTMLElement | HTMLDivElement | null>): boolean => {
  const { bottom = 0, top = 0 } = getCoordsOfElem(ref.current);

  const tooCloseToTop = top < CALENDAR_HEIGHT;
  const windowTooSmall = CALENDAR_HEIGHT > window.innerHeight;
  const tooCloseToBottom = bottom + CALENDAR_HEIGHT > window.innerHeight;

  return !tooCloseToTop && (windowTooSmall || tooCloseToBottom);
};

export interface IRangeDatepickerOwnProps extends IRangeDatepickerProps {
  input: WrappedFieldInputProps;

  inputClassName?: string;
  showSeconds?: boolean;
  placeholder?: string;
}

const RangeDatepicker = ({
  input,
  fromValue,
  toValue,
  inputClassName = ``,
  timePicker,
  hideOnDateChanging = true,
  minDate,
  maxDate = new Date(),
  timezone,
  showSeconds,
  placeholder,
  ...props
}: IRangeDatepickerOwnProps) => {
  const refDatePicker = useRef<HTMLElement | HTMLDivElement | null>(null);
  const refPortal = useRef<HTMLElement | HTMLDivElement | null>(null);
  const refFrom = useRef<HTMLElement | HTMLInputElement | null>(null);
  const refTo = useRef<HTMLElement | HTMLInputElement | null>(null);

  const [t] = useTranslation();
  const [currentDatepicker, setCurrentDatepicker] = useState<RangeDatepickerInput | null>(null);
  const [position, setPosition] = useState<{
    left?: number | string;
    top?: number | string;
    right?: number | string;
    bottom?: number | string;
  }>({
    left: `-100%`,
    top: `-100%`,
  });

  const [timeFrom, setTimeFrom] = useState<string>(fromValue ? moment(fromValue).format(`HH:mm:ss`) : `00:00:00`);
  const [timeTo, setTimeTo] = useState<string>(toValue ? moment(toValue).format(`HH:mm:ss`) : `23:59:59`);
  const [dateFrom, setDateFrom] = useState<Date | string | undefined>(fromValue);
  const [dateTo, setDateTo] = useState<Date | string | undefined>(toValue);

  useUpdateEffect(() => {
    if (fromValue && !dateFrom) {
      setDateFrom(fromValue);
    }
  }, [fromValue]);

  useUpdateEffect(() => {
    if (toValue && !dateTo) {
      setDateTo(toValue);
    }
  }, [toValue]);

  useEffect(() => {
    if (fromValue) {
      setTimeFrom(moment(fromValue).format(`HH:mm:ss`));
    }
  }, [fromValue]);

  useEffect(() => {
    if (toValue) {
      setTimeTo(moment(toValue).format(`HH:mm:ss`));
    }
  }, [toValue]);

  useClickAway(refPortal, (e) => {
    if (!refFrom?.current?.contains(e.target as Node) && !refTo?.current?.contains(e.target as Node)) {
      setCurrentDatepicker(null);
    }
  });

  const onDateChange = useCallback(
    (val: Date, type: DateType) => {
      const datesRange = { values: { startDate: fromValue, endDate: toValue } };
      datesRange.values[type] = val;
      input.onChange(datesRange);
    },
    [fromValue, toValue]
  );

  // useEffect(() => {
  //   if (timeFrom) {
  //     onDateChange(getDateTime(dateFrom, timeFrom), `startDate`);
  //   }
  // }, [timeFrom]);

  // useEffect(() => {
  //   if (timeTo) {
  //     onDateChange(getDateTime(dateTo, timeTo), `endDate`);
  //   }
  // }, [timeTo]);

  const setPortalPosition = (datepickerInput: RangeDatepickerInput | null) => {
    const scrollTop = document.documentElement.scrollTop;
    if (datepickerInput === RangeDatepickerInput.FROM) {
      const { left = 0, bottom = 0, top = 0 } = getCoordsOfElem(refFrom.current);
      const datePickerTop = openTop(refDatePicker) ? top + scrollTop - CALENDAR_HEIGHT : bottom + scrollTop;
      setPosition({
        left,
        top: datePickerTop,
      });
    } else if (datepickerInput === RangeDatepickerInput.TO) {
      const { top = 0, left = 0, right = 0, bottom = 0 } = getCoordsOfElem(refTo.current);
      const datePickerTop = openTop(refDatePicker) ? top + scrollTop - CALENDAR_HEIGHT : bottom + scrollTop;
      if (openLeft(refDatePicker)) {
        setPosition({
          right: window.innerWidth - right,
          top: datePickerTop,
        });
      } else {
        setPosition({
          left,
          top: datePickerTop,
        });
      }
    }
  };

  useUpdateEffect(() => {
    setPortalPosition(currentDatepicker);
  }, [currentDatepicker]);

  const keydownListen = (e: KeyboardEvent) => {
    switch (e.code) {
      case `ArrowUp`:
      case `ArrowDown`:
        setCurrentDatepicker(null);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    window.addEventListener(`wheel`, () => setCurrentDatepicker(null));
    window.addEventListener(`keydown`, keydownListen);
  }, []);

  useUnmount(() => {
    window.removeEventListener(`wheel`, () => setCurrentDatepicker(null));
    window.removeEventListener(`keydown`, keydownListen);
  });

  // useUpdateEffect(() => {
  //   const nextDatepicker = !toValue ? RangeDatepickerInput.TO : null;
  //   if (hideOnDateChanging) {
  //     setCurrentDatepicker(nextDatepicker);
  //   }
  // }, [dateFrom]);

  // useUpdateEffect(() => {
  //   const nextDatepicker = !fromValue ? RangeDatepickerInput.FROM : null;
  //   if (hideOnDateChanging) {
  //     setCurrentDatepicker(nextDatepicker);
  //   }
  // }, [dateTo]);

  const handleInputFocus = useCallback((inputName: RangeDatepickerInput) => {
    setCurrentDatepicker(inputName);
  }, []);

  let calculatedMaxDate, calculatedMinDate, handleTimeChange, handleDateChange, timeValue, dateValue;

  if (maxDate) {
    calculatedMaxDate = moment(maxDate).toDate();
  }

  if (minDate) {
    calculatedMinDate = moment(minDate).toDate();
  }

  if (currentDatepicker === RangeDatepickerInput.FROM) {
    calculatedMaxDate = toValue ? moment(toValue).toDate() : calculatedMaxDate;
    handleTimeChange = (e: ChangeEvent<HTMLInputElement>, val: string) => {
      setTimeFrom(val);
      const result = getDateTime(dateFrom, val);
      onDateChange(result, `startDate`);
    };
    handleDateChange = (date: any) => {
      setDateFrom(date);
      const result = getDateTime(date, timeFrom);
      onDateChange(result, `startDate`);
      if (!dateTo) {
        setCurrentDatepicker(RangeDatepickerInput.TO);
      }
    };
    timeValue = timeFrom;
    dateValue = fromValue;
  } else {
    calculatedMinDate = fromValue ? moment(fromValue).toDate() : calculatedMinDate;
    handleTimeChange = (e: ChangeEvent<HTMLInputElement>, val: string) => {
      setTimeTo(val);
      const result = getDateTime(dateTo, val);
      onDateChange(result, `endDate`);
    };
    handleDateChange = (date: any) => {
      setDateTo(date);
      const result = getDateTime(date, timeTo);
      onDateChange(result, `endDate`);
      if (!dateFrom) {
        setCurrentDatepicker(RangeDatepickerInput.FROM);
      }
    };
    timeValue = timeTo;
    dateValue = toValue;
  }

  return (
    <div className="datepicker__wrap" ref={refDatePicker as RefObject<HTMLDivElement>}>
      {placeholder && <span className="mr-10 flex flex-align-center">{placeholder}</span>}
      <span className="datepicker__item">
        <input
          id={`${input.name}_date_range_from_field`}
          type="text"
          value={displayingDateInputFormat(fromValue, timePicker ? timeFrom : undefined, timezone)}
          ref={refFrom as RefObject<HTMLInputElement>}
          placeholder={t(`common.dateFrom`)}
          className={classNames(`form-control`, `input--datepicker`, inputClassName)}
          onFocus={() => handleInputFocus(RangeDatepickerInput.FROM)}
          onClick={() => handleInputFocus(RangeDatepickerInput.FROM)}
          readOnly
        />
      </span>
      <span className="datepicker__item ml-10">
        <input
          id={`${input.name}_date_range_to_field`}
          type="text"
          value={displayingDateInputFormat(toValue, timePicker ? timeTo : undefined, timezone)}
          ref={refTo as RefObject<HTMLInputElement>}
          placeholder={t(`common.dateTo`)}
          className={classNames(`form-control`, `input--datepicker`, inputClassName)}
          onFocus={() => handleInputFocus(RangeDatepickerInput.TO)}
          onClick={() => handleInputFocus(RangeDatepickerInput.TO)}
          readOnly
        />
      </span>
      {currentDatepicker && (
        <Portal>
          <div
            id="range_datepicker_tooltip"
            className={classNames(`datepicker datepicker--opened`, {
              'datepicker--open-left': openLeft(refDatePicker),
            })}
            style={position}
            ref={refPortal as RefObject<HTMLDivElement>}
          >
            {timePicker && (
              <div className="timepicker__wrap">
                <TimeField
                  value={timeValue}
                  showSeconds={showSeconds}
                  onChange={handleTimeChange}
                  input={<TimeInput />}
                />
              </div>
            )}
            <Calendar
              {...input}
              {...props}
              date={dateValue as DateInputType}
              onChange={handleDateChange as (range: OnChangeProps) => void}
              maxDate={calculatedMaxDate}
              minDate={calculatedMinDate}
            />
          </div>
        </Portal>
      )}
    </div>
  );
};

export default RangeDatepicker;
