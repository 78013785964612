import React, { ComponentType, FunctionComponent, useEffect, useState } from 'react';
import assign from 'lodash/assign';
import toString from 'lodash/toString';
import isNumber from 'lodash/isNumber';
import size from 'lodash/size';
import split from 'lodash/split';
import toNumber from 'lodash/toNumber';
import some from 'lodash/some';
import trim from 'lodash/trim';
import get from 'lodash/get';
import isUrl from 'is-url-superb';

import validateMessages from 'utils/validateMessages';
import { FieldType, InputType } from 'utils/enums';
import {
  amountField,
  digitInput,
  geoField,
  isValidHttpsURL,
  latinDigitsAndSymbolsInput,
  latinDigitsAndSymbolsLowerInput,
  latinDigitsAndSymbolsSpaceInput,
  latinDigitsAndSymbolsStrictInput,
  numInput,
  percentField,
  standardAmountField,
  trimmedInput,
} from 'utils';
import {
  CRYPTOCURRENCY_PRECISION,
  LATIN_DIGITS_AND_SYMBOLS_LOWER_REGEX,
  LATIN_DIGITS_AND_SYMBOLS_REGEX,
  LATIN_DIGITS_AND_SYMBOLS_WITH_SPACE_REGEX,
  STRICT_LATIN_DIGITS_AND_SYMBOLS_LOWER_REGEX,
} from 'utils/constants';
import validateAmount from 'utils/validation/validateAmount';
import useCurrencyField from 'hooks/useCurrencyField';

import { IFieldValidatesParams, IFormField } from 'types/form';

import FormInput from 'components/form/core/FormInput';
import CheckboxGroup from 'components/form/inputs/checkbox/CheckboxGroup';
import { ICurrency } from 'types/currencies';
import moment from 'moment';

type Validation = (value: string) => string | undefined;

const getValidates = ({
  required,
  bothDateRequired,
  email,
  onlyLatinDigitsAndSymbols,
  onlyLatinDigitsAndSymbolsLower,
  onlyLatinDigitsAndSymbolsSpace,
  onlyLatinDigitsAndSymbolsStrict,
  minLength,
  maxLength,
  minAmount,
  maxAmount,
  strictMinAmount,
  amount,
  link,
  password,
  ip,
  httpsUrl,
  standardAmount,
  type,
  isCrypt,
  precision: particularPrecision,
  mustBeDifferentValues,
  jsonFields,
  englishName,
  englishCode,
  regexMatch,
  isProvider,
}: IFieldValidatesParams): Validation[] => {
  const validates = [];

  if (required) {
    validates.push((value: string): string | undefined => {
      if (type === `text-editor`) {
        const textEditorValue = get(value, `value`);

        return size(trim(textEditorValue)) === 0 ? validateMessages().requiredField : undefined;
      }

      value = toString(value);

      return !value ? validateMessages().requiredField : undefined;
    });
  }

  if (isProvider) {
    const isProvider = (val: string) => val[0] === `@`;

    validates.push((value: string): string | undefined => {
      if (!value) return undefined;

      return isProvider(value) ? undefined : validateMessages().providerError;
    });
  }

  if (bothDateRequired && type === InputType.DATE_RANGE) {
    validates.push((value: string): string | undefined => {
      const values = get(value, `values`);

      return values && ((values.startDate && !values.endDate) || (values.endDate && !values.startDate))
        ? validateMessages().bothDateRequired
        : undefined;
    });
  }

  if (email) {
    validates.push((value: string): string | undefined => {
      if (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
        return validateMessages().invalidEmail;
      }

      return undefined;
    });
  }

  if (amount) {
    validates.push(validateAmount);
  }

  if (standardAmount) {
    validates.push((value: string): string | undefined => {
      if (!value) {
        return undefined;
      }

      const precision = particularPrecision || isCrypt ? CRYPTOCURRENCY_PRECISION : 2;
      const regex = new RegExp(`^\\d{1,20}(\\.{1}\\d{0,${precision}})?$`);

      if (size(value) > 0 && !regex.test(value)) {
        return validateMessages().invalidAmount;
      }
      if (toNumber(value) <= 0) {
        return validateMessages().minAmountShouldBeGreater(0);
      }

      return undefined;
    });
  }

  if (onlyLatinDigitsAndSymbols) {
    validates.push((value: string): string | undefined => {
      if (LATIN_DIGITS_AND_SYMBOLS_REGEX.test(value)) {
        return validateMessages().onlyLatinDigitsAndSymbols;
      }

      return undefined;
    });
  }

  if (onlyLatinDigitsAndSymbolsSpace) {
    validates.push((value: string): string | undefined => {
      if (LATIN_DIGITS_AND_SYMBOLS_WITH_SPACE_REGEX.test(value)) {
        return validateMessages().onlyLatinDigitsAndSymbols;
      }

      return undefined;
    });
  }

  if (onlyLatinDigitsAndSymbolsStrict) {
    validates.push((value: string): string | undefined => {
      if (STRICT_LATIN_DIGITS_AND_SYMBOLS_LOWER_REGEX.test(value)) {
        return validateMessages().onlyLatinDigitsAndSymbols;
      }

      return undefined;
    });
  }

  if (onlyLatinDigitsAndSymbolsLower) {
    validates.push((value: string): string | undefined => {
      if (LATIN_DIGITS_AND_SYMBOLS_LOWER_REGEX.test(value)) {
        return validateMessages().onlyLatinDigitsAndSymbols;
      }

      return undefined;
    });
  }

  if (isNumber(minLength)) {
    validates.push((value: string): string | undefined => {
      return size(value) < minLength ? validateMessages().minFieldSize(minLength) : undefined;
    });
  }

  if (isNumber(maxLength)) {
    validates.push((value: string): string | undefined => {
      const checkingValue = type === `text-editor` ? get(value, `value`) : value;

      return size(checkingValue) > maxLength ? validateMessages().maxFieldSize(maxLength) : undefined;
    });
  }

  if (isNumber(minAmount)) {
    validates.push((value: string): string | undefined => {
      return toString(value) && +value < minAmount ? validateMessages().minAmount(minAmount) : undefined;
    });
  }

  if (isNumber(strictMinAmount)) {
    validates.push((value: string): string | undefined => {
      return toString(value) && +value <= strictMinAmount
        ? validateMessages().minAmountShouldBeGreater(strictMinAmount)
        : undefined;
    });
  }

  if (isNumber(maxAmount)) {
    validates.push((value: string): string | undefined => {
      return toString(value) && +value > maxAmount ? validateMessages().maxAmount(maxAmount) : undefined;
    });
  }

  if (link) {
    validates.push((value: string): string | undefined => {
      if (value && !isUrl(value)) {
        return validateMessages().invalidUrl;
      }

      return undefined;
    });
  }

  if (password) {
    validates.push((value: string): string | undefined => {
      const hasWhiteSpace = value && value.indexOf(` `) >= 0;
      if (hasWhiteSpace) {
        return validateMessages().passwordMustNotContainSpaces;
      }

      return undefined;
    });
  }

  if (httpsUrl) {
    validates.push((value: string): string | undefined => {
      if (value && !isValidHttpsURL(value)) {
        return validateMessages().invalidHttpsUrl;
      }

      return undefined;
    });
  }

  if (ip) {
    validates.push((value: string): string | undefined => {
      const ipSeparatedValues = split(value, `.`);
      if (size(ipSeparatedValues) !== 4) {
        return validateMessages().invalidIpFormat;
      } else {
        const wrongType = some(ipSeparatedValues, (val: string) => {
          if (size(trim(val)) === 0) {
            return true;
          }

          const numberedVal: number = toNumber(val);

          return numberedVal < 0 || numberedVal > 255 || toString(numberedVal) !== val;
        });

        if (wrongType) {
          return validateMessages().invalidIpFormat;
        }
      }
    });
  }

  if (type === InputType.TIME_RANGE && mustBeDifferentValues) {
    validates.push((value: string) => {
      const fromTime = get(value, `fromTime`);
      const toTime = get(value, `toTime`);

      if (value && fromTime === toTime) {
        return validateMessages().mustBeDifferentValues;
      }

      return undefined;
    });
  }

  if (jsonFields) {
    validates.push((value: string) => {
      if (value) {
        try {
          const obj = JSON.parse(value);

          for (const { name, dateFormat, valueRange } of jsonFields) {
            if (!(name in obj)) return validateMessages().invalidString;
            const value = obj[name];
            if (dateFormat) {
              const date = moment(value, dateFormat).toDate().toString();
              if (date === `Invalid Date` || !/^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/i.test(value))
                return validateMessages().invalidString;
            }
            if (valueRange && !valueRange.includes(value)) return validateMessages().invalidString;
          }

          return undefined;
        } catch (e) {
          return validateMessages().invalidString;
        }
      }
    });
  }

  if (englishName) {
    validates.push((value: string) => {
      if (value && !/^[A-Za-z0-9\s]+$/i.test(value)) {
        return validateMessages().invalidEnglishName;
      }
    });
  }

  if (englishCode) {
    validates.push((value: string) => {
      if (value && !/^[a-z0-9_]+$/i.test(value)) {
        return validateMessages().invalidEnglishCode;
      }
    });
  }

  if (regexMatch) {
    validates.push((value: string) => {
      if (!regexMatch.regex.test(value)) {
        return regexMatch.message;
      }
    });
  }

  return validates;
};

export interface IFormFieldProps extends IFormField {
  currencies?: ICurrency[];
  ReduxFormField: ComponentType<any>;
  ReduxFormFieldArray: ComponentType<any>;
}

const FormField = ({
  required,
  bothDateRequired,
  password,
  email,
  fieldType,
  numeric,
  onlyLatinDigitsAndSymbols,
  onlyLatinDigitsAndSymbolsLower,
  onlyLatinDigitsAndSymbolsSpace,
  onlyLatinDigitsAndSymbolsStrict,
  component,
  minLength,
  maxLength,
  maxAmount,
  minAmount,
  strictMinAmount,
  trim,
  amount,
  link,
  ip,
  httpsUrl,
  precision,
  standardAmount,
  currency,
  currencies,
  digital,
  percent,
  mustBeDifferentValues,
  jsonFields,
  englishCode,
  englishName,
  regexMatch,
  ReduxFormField,
  ReduxFormFieldArray,
  isProvider,
  isGeo,
  ...props
}: IFormFieldProps) => {
  const [validates, setValidates] = useState<Validation[]>([]);

  const { normalizer: currencyFieldNormalizer, isCrypt } = useCurrencyField({ currency, precision, currencies });

  useEffect(() => {
    setValidates(
      getValidates({
        required,
        bothDateRequired,
        email,
        onlyLatinDigitsAndSymbols,
        onlyLatinDigitsAndSymbolsLower,
        onlyLatinDigitsAndSymbolsSpace,
        onlyLatinDigitsAndSymbolsStrict,
        minLength,
        maxLength,
        amount,
        standardAmount,
        maxAmount,
        minAmount,
        strictMinAmount,
        link,
        password,
        isCrypt,
        precision,
        type: props.type,
        ip,
        httpsUrl,
        mustBeDifferentValues,
        jsonFields,
        englishCode,
        englishName,
        regexMatch,
        isProvider,
      })
    );
  }, [
    required,
    email,
    onlyLatinDigitsAndSymbols,
    onlyLatinDigitsAndSymbolsLower,
    onlyLatinDigitsAndSymbolsSpace,
    onlyLatinDigitsAndSymbolsStrict,
    minLength,
    maxLength,
    amount,
    standardAmount,
    maxAmount,
    minAmount,
    strictMinAmount,
    link,
    password,
    isCrypt,
    precision,
    props.type,
    ip,
    httpsUrl,
    mustBeDifferentValues,
    jsonFields,
    englishCode,
    englishName,
    regexMatch,
    isProvider,
  ]);

  if (fieldType === FieldType.CHECKBOX_GROUP) {
    return <ReduxFormField {...props} component={CheckboxGroup} />;
  } else if (fieldType === FieldType.FIELD_ARRAY) {
    return <ReduxFormFieldArray {...props} component={component as FunctionComponent<any>} />;
  }

  const extraProps = {};
  if (password) {
    assign(extraProps, { type: InputType.PASSWORD });
  }
  if (trim) {
    assign(extraProps, { normalize: trimmedInput });
  }
  if (numeric) {
    assign(extraProps, { normalize: numInput });
  }
  if (digital) {
    assign(extraProps, { normalize: digitInput });
  }
  if (amount) {
    assign(extraProps, { normalize: amountField });
  }
  if (standardAmount) {
    if (currency || precision) {
      assign(extraProps, { normalize: currencyFieldNormalizer });
    } else {
      assign(extraProps, { normalize: standardAmountField });
    }
  }
  if (percent) {
    assign(extraProps, { normalize: percentField });
  }
  if (onlyLatinDigitsAndSymbols) {
    assign(extraProps, { normalize: latinDigitsAndSymbolsInput });
  }
  if (onlyLatinDigitsAndSymbolsLower) {
    assign(extraProps, { normalize: latinDigitsAndSymbolsLowerInput });
  }
  if (onlyLatinDigitsAndSymbolsSpace) {
    assign(extraProps, { normalize: latinDigitsAndSymbolsSpaceInput });
  }
  if (onlyLatinDigitsAndSymbolsStrict) {
    assign(extraProps, { normalize: latinDigitsAndSymbolsStrictInput });
  }
  if (isGeo) {
    assign(extraProps, { normalize: geoField });
  }

  return <ReduxFormField {...props} {...extraProps} component={component} validate={validates} />;
};

FormField.defaultProps = {
  required: false,
  password: false,
  email: false,
  numeric: false,
  amount: false,
  link: false,
  onlyLatinDigitsAndSymbols: false,
  component: FormInput,
  trim: false,
  mustBeDifferentValues: false,
  isProvider: false,
  fieldType: FieldType.INPUT,
};

export default FormField;
