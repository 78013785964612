import React from 'react';
import PhoneInput from 'react-phone-input-2';
import { WrappedFieldInputProps } from 'redux-form';
import { useMount } from 'react-use';

import { IPhoneInputProps } from 'types/form';

import 'react-phone-input-2/lib/style.css';

interface inputProps extends IPhoneInputProps, WrappedFieldInputProps {}

const PhoneInputField = ({
  onChange,
  value,
  initialValue,
  countryButtonHidden,
  disableDropdown,
  countryCodeEditable,
}: inputProps) => {
  useMount(() => {
    if (initialValue) {
      onChange(initialValue);
    }
  });

  return (
    <PhoneInput
      value={value}
      onChange={(v) => onChange(v)}
      disableDropdown={disableDropdown}
      countryCodeEditable={countryCodeEditable}
      inputClass={`form-input_reconstruction ${countryButtonHidden ? `form-input-pl-14` : ``}`}
      buttonClass={countryButtonHidden ? `flag-dropdown_hidden` : undefined}
    />
  );
};

export default PhoneInputField;
