import { MutableRefObject, useEffect, useState } from 'react';

const useRect = (ref: MutableRefObject<any>): ClientRect | Record<string, unknown> => {
  const [rect, setRect] = useState<ClientRect | Record<string, unknown>>({});

  useEffect(() => {
    if (ref && ref.current) {
      setRect(ref.current.getBoundingClientRect());
    }
  }, [ref]);

  return rect;
};

export default useRect;
