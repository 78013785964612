import React, { ComponentType, ReactNode, useCallback } from 'react';
import map from 'lodash/map';
import filter from 'lodash/filter';
import get from 'lodash/get';
import { InjectedFormProps } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { Dispatch } from 'redux';

import { IAccordionFormField, IFormField, IGroupedFormField } from 'types/form';
import { IModalProps } from 'types/common';

import ModalElement from 'components/modals/ModalElement';
import ErrorBlock from 'components/base/ErrorBlock';
import ModalFormSubmit from 'components/form/buttons/ModalFormSubmit';
import AccordionComponent from 'components/base/AccordionComponent';

export interface IModalFormProps extends Omit<IModalProps, `content` | `footer` | `modal`>, InjectedFormProps<any> {
  fields: IFormField[];
  form: string;
  onSubmit: (values: any, dispatch: Dispatch) => void;
  submitText: string;
  FormField: ComponentType<IFormField>;

  values?: Record<string, unknown> | null;
  disabled?: boolean;
  forcedShowingError?: boolean;

  preContent?: ReactNode;
  postContent?: ReactNode;
  submitButtonId?: string;
}

const ModalForm = ({
  title,
  actionCreators,
  loading,
  size,
  fields,
  onSubmit,
  submitText,
  disabled,
  error,
  submitFailed,
  forcedShowingError,
  preContent,
  postContent,
  submitButtonId,
  dispatch,
  values,
  submitting,
  FormField,
}: IModalFormProps) => {
  const [t] = useTranslation();

  const submitHandler = useCallback(
    (e) => {
      e.preventDefault();
      onSubmit(values, dispatch);
    },
    [values, dispatch, onSubmit]
  );

  const content = (
    <form onSubmit={submitHandler}>
      {preContent}
      {map(
        filter(fields, (field) => field),
        (field: IFormField | IGroupedFormField | IAccordionFormField, key) => {
          const fieldId = get(field, `name`, key);

          if (field.type === `group` && (field as IGroupedFormField).fields) {
            return (
              <div key={fieldId} className="mb-20">
                {field.title && <span className="font-weight-bold mb-10 display-block">{t(field.title)}</span>}
                {map((field as IGroupedFormField).fields, (nestedField, nestedKey) => {
                  const nestedFieldId = get(nestedField, `name`, nestedKey);

                  return <FormField key={nestedFieldId} isModalField {...nestedField} />;
                })}
              </div>
            );
          }

          if (field.type === `accordion` && (field as IAccordionFormField).fields) {
            return (
              <AccordionComponent key={fieldId} title={t((field as IAccordionFormField).title)}>
                {map((field as IAccordionFormField).fields, (nestedField, nestedKey) => {
                  const nestedFieldId = get(nestedField, `name`, nestedKey);

                  return <FormField key={nestedFieldId} isModalField {...nestedField} />;
                })}
              </AccordionComponent>
            );
          }

          return <FormField key={fieldId} isModalField {...(field as IFormField)} />;
        }
      )}
      {postContent}
    </form>
  );

  const errorBlock = (forcedShowingError || submitFailed) && error && <ErrorBlock error={error} />;

  const footer = (
    <>
      {errorBlock}
      <ModalFormSubmit
        submitting={submitting}
        disabled={disabled || submitting}
        onClick={submitHandler}
        id={submitButtonId}
      >
        {t(submitText)}
      </ModalFormSubmit>
    </>
  );

  return (
    <ModalElement
      dispatch={dispatch}
      title={title}
      actionCreators={actionCreators}
      loading={loading}
      footer={footer}
      size={size}
      content={content}
    />
  );
};

ModalForm.defaultProps = {
  disabled: false,
  submitButtonId: `submit_modal_form_btn`,
};

export default ModalForm;
