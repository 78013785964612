import React, { ReactNode } from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

interface CustomizedAccordion {
  children: ReactNode;
  title: string;
}
const Accordion = withStyles({
  root: {
    border: `1px solid rgba(0, 0, 0, .125)`,
    boxShadow: `none`,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: `none`,
    },
    '&$expanded': {
      margin: `auto`,
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: `rgba(0, 0, 0, .03)`,
    borderBottom: `1px solid rgba(0, 0, 0, .125)`,
    marginBottom: -1,
    minHeight: 34,
    '&$expanded': {
      minHeight: 34,
    },
  },
  content: {
    '&$expanded': {
      margin: `12px 0`,
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    flexDirection: `column`,
  },
}))(MuiAccordionDetails);

const CustomizedAccordions = ({ children, title }: CustomizedAccordion) => {
  const [expanded, setExpanded] = React.useState<boolean>(true);

  const handleChange = () => (event: React.ChangeEvent<Record<string, unknown>>, newExpanded: boolean) => {
    setExpanded(newExpanded);
  };

  return (
    <Accordion square expanded={expanded} onChange={handleChange()}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1d-content" id="panel1d-header">
        <Typography>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

export default CustomizedAccordions;
