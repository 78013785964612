import React, { ReactNode, useState } from 'react';
import classNames from 'classnames';
import map from 'lodash/map';
import filter from 'lodash/filter';
import toString from 'lodash/toString';

import useToggle from 'hooks/useToggle';

interface IButton {
  href?: string;
  className?: string;
  onClick?: () => void;
}

export interface IDropdownButtonsProps {
  className?: string;
  buttons: IButton[];
  children: ReactNode;
  id: string | number;
}

const DropdownButtons = ({ className, children, buttons, id }: IDropdownButtonsProps) => {
  const [availableButtons] = useState<IButton[]>(filter(buttons, (button) => button) as IButton[]);

  const { isOpened, ref, onToggle, onClose } = useToggle({
    hideOnClickAway: true,
  });

  const buttonClassName = classNames(`btn dropdown-button__button`, className);

  return (
    <div ref={ref} className="position-relative">
      <button className={buttonClassName} onClick={onToggle} id={toString(id)}>
        <div>{children}</div>
        <i className="ml-5 si si-arrow-down font-weight-bold" />
      </button>
      {isOpened && (
        <div className="dropdown-button__dropdown">
          {map(availableButtons, ({ href, onClick, className, ...props }: IButton, key) => {
            const dropdownButtonClassName = classNames(`btn`, className);

            let clickCallback;
            if (onClick) {
              clickCallback = () => {
                onClick();
                onClose();
              };
            } else {
              clickCallback = onClick;
            }

            if (href) {
              return <a {...props} href={href} onClick={clickCallback} key={key} className={dropdownButtonClassName} />;
            }

            return <button {...props} onClick={clickCallback} className={dropdownButtonClassName} key={key} />;
          })}
        </div>
      )}
    </div>
  );
};

export default DropdownButtons;
