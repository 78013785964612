import React, {
  useEffect,
  useCallback,
  useRef,
  useState,
  ChangeEvent,
  RefObject,
  HTMLProps,
  MutableRefObject,
  useMemo,
} from 'react';
import classNames from 'classnames';
import { Calendar, DateInputType, OnChangeProps } from 'react-date-range';
import useClickAway from 'react-use/lib/useClickAway';
import TimeField from 'react-simple-timefield';
import useUpdateEffect from 'react-use/lib/useUpdateEffect';
import moment from 'moment';
import { Portal } from 'react-portal';
import { WrappedFieldInputProps } from 'redux-form/lib/Field';
import { getCoordsOfElem } from 'utils';
import { IRangeDatepickerProps } from 'types/form';
import { displayingDateInputFormat } from './RangeDatePicker';

const CALENDAR_HEIGHT = 300;

const TimeInput = (props: HTMLProps<HTMLInputElement>) => (
  <input {...props} className="timepicker__input form-control" />
);

const getDateTime = (date?: string | Date, time?: string): Date => {
  date = moment(date).format(`YYYY-MM-DD`);

  return moment(`${date} ${time}`).toDate();
};

const openTop = (ref: MutableRefObject<HTMLElement | HTMLDivElement | null>): boolean => {
  const { bottom = 0, top = 0 } = getCoordsOfElem(ref.current);

  const tooCloseToTop = top < CALENDAR_HEIGHT;
  const windowTooSmall = CALENDAR_HEIGHT > window.innerHeight;
  const tooCloseToBottom = bottom + CALENDAR_HEIGHT > window.innerHeight;

  return !tooCloseToTop && (windowTooSmall || tooCloseToBottom);
};

export interface IRangeDatepickerOwnProps extends IRangeDatepickerProps {
  input: WrappedFieldInputProps;

  inputClassName?: string;
  showSeconds?: boolean;
  placeholder?: string;
}

const DateTimepicker = ({
  input,
  fromValue,
  inputClassName = ``,
  timePicker,
  hideOnDateChanging = true,
  minDate,
  maxDate = new Date(),
  timezone,
  showSeconds,
  placeholder,
  ...props
}: IRangeDatepickerOwnProps) => {
  const refDatePicker = useRef<HTMLElement | HTMLDivElement | null>(null);
  const refPortal = useRef<HTMLElement | HTMLDivElement | null>(null);
  const refFrom = useRef<HTMLElement | HTMLInputElement | null>(null);
  const [currentDatepicker, setCurrentDatepicker] = useState<boolean>(false);
  const [position, setPosition] = useState<{
    left?: number | string;
    top?: number | string;
    right?: number | string;
    bottom?: number | string;
  }>({
    left: `-100%`,
    top: `-100%`,
  });

  const [time, setTime] = useState<string>(fromValue ? moment(fromValue).format(`HH:mm:ss`) : `00:00:00`);
  const [date, setDate] = useState<Date | string | undefined>(fromValue);

  const inputData = moment(fromValue).format(`DD.MM.YYYY HH:mm:ss`);

  useEffect(() => {
    if (fromValue) {
      setDate(fromValue);
    }
  }, [inputData]);

  useEffect(() => {
    if (fromValue) {
      setTime(moment(fromValue).format(`HH:mm:ss`));
    }
  }, [inputData]);

  useClickAway(refPortal, (e) => {
    if (!refFrom?.current?.contains(e.target as Node)) {
      setCurrentDatepicker((props) => !props);
    }
  });

  const onDateChange = useCallback(
    (val: Date) => {
      const datesRange = val ? val : fromValue;
      input.onChange(datesRange);
    },
    [fromValue]
  );

  useUpdateEffect(() => {
    onDateChange(getDateTime(date, time));
  }, [date, time]);

  const setPortalPosition = () => {
    const scrollTop = document.documentElement.scrollTop;
    const { left = 0, bottom = 0, top = 0 } = getCoordsOfElem(refFrom.current);
    const datePickerTop = openTop(refDatePicker) ? top + scrollTop - CALENDAR_HEIGHT : bottom + scrollTop;
    setPosition({
      left,
      top: datePickerTop,
    });
  };

  useUpdateEffect(() => {
    setPortalPosition();
  }, [currentDatepicker]);

  const handleInputFocus = useCallback(() => {
    setCurrentDatepicker((prevState) => !prevState);
  }, []);

  let handleTimeChange, handleDateChange, timeValue, dateValue;

  if (currentDatepicker) {
    handleTimeChange = (e: ChangeEvent<HTMLInputElement>, val: string) => setTime(val);
    handleDateChange = setDate;
    timeValue = time;
    dateValue = fromValue;
  }

  return (
    <div className="datepicker__wrap" ref={refDatePicker as RefObject<HTMLDivElement>}>
      {placeholder && <span className="mr-10 flex flex-align-center">{placeholder}</span>}
      <span className="datepicker__item">
        <input
          id={`${input.name}_date_range_from_field`}
          type="text"
          value={displayingDateInputFormat(fromValue, timePicker ? time : undefined, timezone)}
          ref={refFrom as RefObject<HTMLInputElement>}
          className={classNames(`form-control`, `input--datepicker`, inputClassName)}
          onFocus={handleInputFocus}
          onClick={handleInputFocus}
          readOnly
        />
      </span>
      {currentDatepicker && (
        <Portal>
          <div
            id="range_datepicker_tooltip"
            className="datepicker datepicker--opened datepicker--open-left"
            style={position}
            ref={refPortal as RefObject<HTMLDivElement>}
          >
            {timePicker && (
              <div className="timepicker__wrap">
                <TimeField
                  value={timeValue}
                  showSeconds={showSeconds}
                  onChange={handleTimeChange}
                  input={<TimeInput />}
                />
              </div>
            )}
            <Calendar
              {...input}
              {...props}
              date={dateValue as DateInputType}
              onChange={handleDateChange as (range: OnChangeProps) => void}
            />
          </div>
        </Portal>
      )}
    </div>
  );
};

export default DateTimepicker;
