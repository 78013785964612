import useMount from 'react-use/lib/useMount';
import { IUseItemProps } from 'types/widgets';

export interface IUseItemResult<Item = any> {
  item: Item | null;
  itemLoading: boolean;
}

const useWidgetItem = <Item = any>({
  actionCreators: { getItem },
  payload,
  loadOnlyIfNecessary,
  dispatch,
  item,
  itemLoading,
}: IUseItemProps<Item>): IUseItemResult<Item> => {
  useMount(() => {
    if (loadOnlyIfNecessary && item) {
      return;
    }

    dispatch(getItem(payload));
  });

  return { item, itemLoading };
};

export default useWidgetItem;
