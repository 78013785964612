import React from 'react';

export interface IErrorBlockProps {
  error: string;
}

const ErrorBlock = ({ error }: IErrorBlockProps) => {
  return (
    <div className="error-block mb-10">
      <pre className="text-error">{error}</pre>
    </div>
  );
};

export default ErrorBlock;
