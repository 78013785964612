import React, { ReactNode, useState } from 'react';
import map from 'lodash/map';
import isString from 'lodash/isString';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { AnyAction, Dispatch } from 'redux';

import useDesktopSize from 'hooks/useDesktopSize';
import { searchProvider } from 'utils/pagination';
import { ITableContainerProps } from 'types/components';
import { IFilterSearchParams } from 'types/widgets';

export interface ITableContentContainerProps extends ITableContainerProps {
  perPage: number;
  search: IFilterSearchParams;
  dispatch: Dispatch;

  title?: string | ReactNode;
  onRefresh?: (payload?: any) => AnyAction;
  children?: ReactNode;
  loading?: boolean;
  withPagination?: boolean;
  withSuperAdminPagination?: boolean;
}

const TableContentContainer = ({
  children,
  title,
  onRefresh,
  extraHeaderButtons,
  loading,
  extraHeaderContent,
  translatedTitle,
  refreshingIsDisabled,
  perPage,
  search,
  dispatch,
  withPagination,
  withSuperAdminPagination,
}: ITableContentContainerProps) => {
  const [t] = useTranslation();

  const [refreshedOnce, setRefreshedOnce] = useState(false);

  const desktopSize = useDesktopSize();

  const onRefreshHandler = () => {
    if (!onRefresh) {
      return;
    }

    const searchProviderParams = withPagination || withSuperAdminPagination ? { perPage } : {};

    dispatch(onRefresh(searchProvider(search, searchProviderParams)));
    if (!refreshedOnce) {
      setRefreshedOnce(true);
    }
  };

  return (
    <div className="purses-list-block block">
      <div className="block-header block-header-default">
        <div className="table-content__left">
          <h3 className="table-content__title">{translatedTitle && isString(title) ? t(title) : title}</h3>
          {extraHeaderButtons &&
            map(extraHeaderButtons, ({ children, onClick, className, withDispatch = true, href, ...props }, i) => {
              const clickHandler = withDispatch ? () => dispatch(onClick() as AnyAction) : onClick;

              if (!isString(className)) {
                className = classNames(`btn`, `btn-alt-primary`, { 'ml-20': i !== 0 && desktopSize });
              }

              if (href) {
                return (
                  <a {...(props as any)} href={href} key={i} className={className}>
                    {children}
                  </a>
                );
              }

              return (
                <button {...(props as any)} key={i} onClick={clickHandler} className={className}>
                  {children}
                </button>
              );
            })}
          {extraHeaderContent}
        </div>
        {!!onRefresh && (
          <button
            disabled={refreshingIsDisabled}
            type="button"
            onClick={onRefreshHandler}
            className="btn btn-alt-success table-header-btn"
            id="refresh_list_btn"
          >
            <i className={classNames(`si`, `si-refresh`, { 'update-btn-rotate': refreshedOnce && loading })} />
          </button>
        )}
      </div>
      <div className={classNames(`block-content`, `table-content__content`, { 'no-padding': !desktopSize })}>
        {children}
      </div>
    </div>
  );
};

TableContentContainer.defaultProps = {
  extraHeaderButtons: [],

  title: ``,
  children: null,

  loading: false,
  withPagination: false,
  withSuperAdminPagination: false,
  translatedTitle: true,
  refreshingIsDisabled: false,
};

export default TableContentContainer;
