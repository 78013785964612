import React, { useCallback, useState } from 'react';
import get from 'lodash/get';
import classNames from 'classnames';

export interface IChangeParamsProvider {
  setEditMode: (edit: boolean) => void;
  setError: (err: string | null) => void;
}

export interface ITableChangeableInputProps {
  rowId: number | string;
  onChange: (params: IChangeParamsProvider) => (val?: string) => void;

  value?: string;
  normalize?: (val: string) => string;
}

const TableChangeableInput = ({ value, onChange, rowId, normalize }: ITableChangeableInputProps) => {
  const [inputValue, setInputValue] = useState<string | undefined>(value);
  const [error, setError] = useState<string | null>(null);
  const [editMode, setEditMode] = useState<boolean>(false);

  const onSetChanges = useCallback(() => {
    onChange({ setEditMode, setError })(inputValue);
  }, [inputValue, setEditMode]);

  if (editMode) {
    return (
      <div className={classNames(`flex align-items-center`, { 'is-invalid': error })}>
        <div className="mr-5">
          <input
            type="text"
            onChange={(e) => {
              const value = (normalize as (val: string) => string)(get(e, `target.value`));
              setInputValue(value);
            }}
            value={inputValue}
            id={`row_${rowId}_changeable_input_field`}
            className="form-control"
          />
          {error && <span className="text-danger">{error}</span>}
        </div>
        <button onClick={onSetChanges} className="mr-5" type="button" id={`row_${rowId}_changeable_input_save_btn`}>
          <i className="fa fa-check text-success font-size-xl" />
        </button>
        <button onClick={() => setEditMode(false)} type="button" id={`row_${rowId}_changeable_input_cancel_btn`}>
          <i className="fa fa-close text-danger font-size-xl" />
        </button>
      </div>
    );
  }

  return (
    <div className="flex align-items-center">
      <span className="mr-5" id={`row_${rowId}_changeable_input_text`}>
        {value}
      </span>
      <button
        onClick={() => {
          setInputValue(value);
          setEditMode(true);
        }}
        type="button"
        id={`row_${rowId}_changeable_input_edit_mode_btn`}
      >
        <i className="fa fa-pencil font-size-xl" />
      </button>
    </div>
  );
};

TableChangeableInput.defaultProps = {
  normalize: (val: string): string => val,
};

export default TableChangeableInput;
