import React, { ReactNode } from 'react';
import classNames from 'classnames';

export interface IPageContainerProps {
  children: ReactNode;
}

const PageContainer = ({ children }: IPageContainerProps) => (
  <div
    id="page-container"
    className={classNames(
      `sidebar-inverse`,
      `side-scroll`,
      `page-header-fixed`,
      `page-header-inverse`,
      `main-content-boxed`,
      `side-trans-enabled`
    )}
  >
    {children}
  </div>
);

export default PageContainer;
