import { useEffect, useState } from 'react';
import map from 'lodash/map';
import get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import { ISelectData } from 'types/form';

const useDropdownTranslator = (data: ISelectData[] = [], translateKeys = false): ISelectData[] => {
  const [t] = useTranslation();

  const [list, setList] = useState<ISelectData[]>([]);

  useEffect(() => {
    if (translateKeys) {
      setList(
        map(data, (item) => ({
          ...item,
          text: t(get(item, `text`)),
        }))
      );
    } else {
      setList(data || []);
    }
  }, [data, setList, t, translateKeys]);

  return list;
};

export default useDropdownTranslator;
