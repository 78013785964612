import React, { CSSProperties, ReactNode, useEffect, useRef, useState } from 'react';
import useHover from 'react-use/lib/useHover';
import classNames from 'classnames';
import { Portal } from 'react-portal';

import { getCoordsOfElem } from 'utils';

export interface ITooltipProps {
  insideContent?: ReactNode;
  children?: ReactNode;
  style?: CSSProperties;
}

const Tooltip = ({ insideContent, children, style }: ITooltipProps) => {
  const [openToTop, setOpenToTop] = useState(false);
  const [position, setPosition] = useState({ left: 0, top: 0, width: 0 });
  const ref = useRef(null);

  const element = (hovered: boolean) => (
    <div className="tooltip__wrap" ref={ref}>
      {children}
      {hovered && (
        <Portal>
          <div className="tooltip__portal" style={position}>
            <div className={classNames(`tooltip__elem`, { 'tooltip__elem--to-top': openToTop })} style={style}>
              {insideContent}
            </div>
          </div>
        </Portal>
      )}
    </div>
  );

  const [hoverable, hovered] = useHover(element);

  useEffect(() => {
    if (hovered && ref) {
      const { top = 0, left = 0, right = 0, bottom = 0 } = getCoordsOfElem(ref.current);
      const isOpenToTop = window.innerHeight - top < 300;
      setPosition({
        left,
        top: (isOpenToTop ? top : bottom) + document.documentElement.scrollTop,
        width: right - left,
      });
      setOpenToTop(isOpenToTop);
    }
  }, [hovered, ref]);

  return hoverable;
};

export default Tooltip;
