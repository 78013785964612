import useMount from 'react-use/lib/useMount';
import useUnmount from 'react-use/lib/useUnmount';
import isNull from 'lodash/isNull';

import { IUseListParams } from 'types/widgets';

export interface IUseListResult<Item = any> {
  items: Item[] | null;
  listLoading: boolean;
}

const useList = <Item = any>({
  actionCreators,
  queryParams,
  resetOnUnmount = true,
  loadOnlyIfNecessary = false,
  disableLoading = false,
  items,
  listLoading,
  dispatch,
}: IUseListParams): IUseListResult<Item> => {
  useMount(() => {
    if ((loadOnlyIfNecessary && !isNull(items)) || disableLoading) {
      return;
    }

    dispatch(actionCreators.getList(queryParams));
  });

  useUnmount(() => {
    if (resetOnUnmount) {
      dispatch(actionCreators.resetList());
    }
  });

  return { items, listLoading };
};

export default useList;
