import { takeEvery, put, select } from 'redux-saga/effects';
import pickBy from 'lodash/pickBy';
import size from 'lodash/size';
import trim from 'lodash/trim';
import mapValues from 'lodash/mapValues';
import each from 'lodash/each';
import unset from 'lodash/unset';
import isString from 'lodash/isString';
import assign from 'lodash/assign';
import get from 'lodash/get';
import isObject from 'lodash/isObject';
import isNumber from 'lodash/isNumber';
import { getLocation, push } from 'connected-react-router';
import { ToastrEmitter } from 'react-redux-toastr';
import moment from 'moment';
import { ParsedUrlQueryInput } from 'querystring';
import omit from 'lodash/omit';

import { SUBMIT_FILTERS } from 'actionTypes';
import i18n from '../i18n';
import validateForm from 'sagas/effects/validateForm';
import { IAction, IActionMeta } from 'types/common';
import { IFiltersMeta } from 'types/filters';
import { updateSearchParams } from 'utils';
import { formUnusedSelector } from 'selectors/form';

const convertDateToRequiredFormat = (date: Date | string | number | null, dateFormat: string): string | null => {
  return date ? moment(new Date(date)).format(dateFormat) : null;
};

export interface IFiltersSagasParams {
  toastr: ToastrEmitter;
}

const getFiltersSagas = ({ toastr }: IFiltersSagasParams) => {
  function* filtersSubmissionSaga({ payload: values, meta }: IAction<Record<string, unknown>, IFiltersMeta>) {
    const { dateFields = [], onSuccess, dateFormat = `YYYY-MM-DD`, form, ...restMeta } = meta || {};

    // @ts-ignore
    const unusedFields = yield select(formUnusedSelector(form)) || [];
    const usedValues = omit(values, unusedFields);

    if (form) {
      // @ts-ignore
      const valid = yield validateForm({ form, meta: restMeta as IActionMeta });
      if (!valid) {
        return;
      }
    }

    let errorWasFound = false;

    let data: ParsedUrlQueryInput = pickBy(usedValues, (value) => {
      return isNumber(value) || (isString(value) && size(trim(value)) > 0) || isObject(value);
    }) as ParsedUrlQueryInput;
    data = mapValues(data, (value) => {
      if (isString(value)) {
        return trim(value);
      }

      return value;
    });

    each(dateFields, ({ name, fromName, toName }) => {
      if (isString(name) && isString(fromName) && isString(toName)) {
        const from = get(data, `${name}.values.startDate`, null) as Date | string | null;
        const to = get(data, `${name}.values.endDate`, null) as Date | string | null;

        const fromValue = convertDateToRequiredFormat(from, dateFormat);
        const toValue = convertDateToRequiredFormat(to, dateFormat);
        if (from && to && fromValue && toValue) {
          if (new Date(to).getTime() >= new Date(from).getTime()) {
            assign(data, { [fromName]: fromValue, [toName]: toValue });
            unset(data, name);
          } else {
            toastr.error(i18n.t(`common.error`), i18n.t(`errors.invalidDateRange`));
            errorWasFound = true;
          }
        } else if ((fromValue && !toValue) || (!fromValue && toValue)) {
          toastr.error(i18n.t(`common.error`), i18n.t(`errors.dateRangeIsRequired`));
          errorWasFound = true;
        }
      }
    });

    if (!errorWasFound) {
      // @ts-ignore
      const location = yield select(getLocation);
      yield put(push(updateSearchParams(location, data)));

      if (onSuccess) onSuccess();
    }
  }

  return [takeEvery(SUBMIT_FILTERS, filtersSubmissionSaga)];
};

export default getFiltersSagas;
