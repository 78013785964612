import React, { ChangeEvent } from 'react';
import classNames from 'classnames';
import Switch from '@material-ui/core/Switch';
import { useTranslation } from 'react-i18next';

import useIsTouchDevice from 'hooks/useIsTouchDevice';

export interface IMaterialUICheckboxProps {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;

  checked?: number | boolean;
  label?: string;
  labelClassName?: string;
  description?: string;
}

const MaterialUICheckbox = ({ onChange, checked, label, labelClassName, description }: IMaterialUICheckboxProps) => {
  const [t] = useTranslation();

  const isTouchDevice = useIsTouchDevice();

  return (
    <div className={classNames(`row`, labelClassName)}>
      <Switch color="primary" checked={!!checked} onChange={onChange} />
      <label
        className={classNames(
          `checkbox-group__label css-control css-control-success css-switch css-control-sm`,
          labelClassName
        )}
      >
        <span>
          {label}
          {description &&
            (isTouchDevice ? (
              <p className="text-muted font-size-sm">{t(description)}</p>
            ) : (
              <i className="fa fa-question-circle-o ml-5" title={t(description)} />
            ))}
        </span>
        <span className="css-control-indicator" />
      </label>
    </div>
  );
};

export default MaterialUICheckbox;
