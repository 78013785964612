import React, { ChangeEvent } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import useIsTouchDevice from 'hooks/useIsTouchDevice';

export interface ICheckboxProps {
  name: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;

  label?: string;
  isModalField?: boolean;
  checkboxLabelClassName?: string;
  checked?: boolean;
  wrapClassName?: string;
  disabled?: boolean;
  description?: string;
}

const Checkbox = ({
  label,
  name,
  onChange,
  isModalField,
  checkboxLabelClassName,
  checked,
  wrapClassName,
  disabled,
  description,
}: ICheckboxProps) => {
  const [t] = useTranslation();

  const isTouchDevice = useIsTouchDevice();

  if (isModalField) {
    return (
      <div className={classNames(`modal-form__checkbox`, wrapClassName)}>
        <label
          className={classNames(
            `checkbox-group__label css-control css-control-success css-switch css-control-sm`,
            checkboxLabelClassName
          )}
        >
          <span>
            {label}
            {description &&
              (isTouchDevice ? (
                <p className="text-muted font-size-sm">{t(description)}</p>
              ) : (
                <i className="fa fa-question-circle-o ml-5" title={t(description)} />
              ))}
          </span>
          <input
            type="checkbox"
            className="css-control-input"
            name={name}
            checked={checked}
            onChange={onChange}
            disabled={disabled}
          />
          <span className="css-control-indicator" />
        </label>
      </div>
    );
  }

  return (
    <div className={classNames(`form-group`, wrapClassName)}>
      <label
        className={classNames(`css-control css-control-success css-switch css-control-sm`, checkboxLabelClassName)}
      >
        <input type="checkbox" className="css-control-input" name={name} checked={checked} onChange={onChange} />
        <span className="css-control-indicator mr-5" />
        {label}
        {description && <i className="fa fa-question-circle-o ml-5" title={t(description)} />}
      </label>
    </div>
  );
};

Checkbox.defaultProps = {
  isModalField: false,
  checked: false,
};

export default Checkbox;
