import React, { ComponentType } from 'react';
import map from 'lodash/map';
import get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import { Dispatch } from 'redux';
import { InjectedFormProps } from 'redux-form';

import { IWidgetActionCreators } from 'types/widgets';
import { IFormField } from 'types/form';
import { Size } from 'utils/enums';

import ModalFormSubmit from 'components/form/buttons/ModalFormSubmit';
import ModalElement from 'components/modals/ModalElement';

interface IRow {
  fields: IFormField[];
}

export interface ITableModalFormProps<FormData = any> extends InjectedFormProps<FormData> {
  actionCreators: IWidgetActionCreators;
  title: string;
  rows: IRow[];
  headTitles: string[];
  submitText: string;
  dispatch: Dispatch;
  FormField: ComponentType<IFormField>;

  disabled?: boolean;
  size?: Size;
  loading?: boolean;
  footerClassName?: string;
}

function TableModalForm<FormData = any>({
  rows,
  headTitles,
  submitText,
  submitFailed,
  error,
  submitting,
  disabled,
  actionCreators,
  title,
  size,
  loading,
  footerClassName,
  handleSubmit,
  dispatch,
  FormField,
}: ITableModalFormProps<FormData>) {
  const [t] = useTranslation();

  const content = (
    <form>
      <div className="table-responsive">
        <table>
          <thead>
            <tr>
              {map(headTitles, (title, key) => (
                <th className="p-5" key={key}>
                  {t(title)}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {map(rows, ({ fields }, key) => (
              <tr key={key}>
                {map(fields, (field, key) => {
                  let fieldElement;
                  if ((get(field, `fieldType`) as unknown as string) === `static`) {
                    fieldElement = <div className="mt-7">{get(field, `value`)}</div>;
                  } else {
                    fieldElement = <FormField {...field} formGroup={false} />;
                  }

                  return (
                    <td valign="top" className="p-5" key={key}>
                      {fieldElement}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </form>
  );

  const footer = (
    <>
      {submitFailed && error && (
        <div className="error-block mb-5">
          <pre className="text-error">{error}</pre>
        </div>
      )}
      <ModalFormSubmit onClick={handleSubmit} submitting={submitting} disabled={disabled}>
        {t(submitText)}
      </ModalFormSubmit>
    </>
  );

  return (
    <ModalElement
      dispatch={dispatch}
      actionCreators={actionCreators}
      title={title}
      footer={footer}
      size={size}
      loading={loading}
      content={content}
      footerClassName={footerClassName}
    />
  );
}

export default TableModalForm;
