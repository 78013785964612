import { useState, useRef, MutableRefObject } from 'react';
import useKeyPressEvent from 'react-use/lib/useKeyPressEvent';
import useClickAway from 'react-use/lib/useClickAway';

import { pressEsc } from 'utils';

export interface IUseToggleParams {
  hideOnClickAway?: boolean;
  hideOnEsc?: boolean;
}

export interface IUseToggleResult {
  onClose: () => void;
  onToggle: () => void;
  isOpened: boolean;
  ref: MutableRefObject<any>;
}

const useToggle = ({ hideOnClickAway = false, hideOnEsc = true }: IUseToggleParams = {}): IUseToggleResult => {
  const [isOpened, setIsOpened] = useState(false);

  const onClose = () => setIsOpened(false);

  const ref = useRef(null);

  useKeyPressEvent(pressEsc, () => {
    if (hideOnEsc) {
      onClose();
    }
  });

  const onToggle = () => setIsOpened(!isOpened);

  useClickAway(ref, () => {
    if (hideOnClickAway) {
      onClose();
    }
  });

  return { onClose, onToggle, isOpened, ref };
};

export default useToggle;
