import { useWindowSize } from 'react-use';

import { DESKTOP_SIZE } from 'utils/constants';

const useDesktopSize = (): boolean => {
  const { width } = useWindowSize();

  return width >= DESKTOP_SIZE;
};

export default useDesktopSize;
