import { IAuthState, IAuthUser } from 'types/auth';
import { IRootState } from 'types/common';
import { createSelector } from 'reselect';

import { AuthStatus } from 'utils/enums';

// Todo: userPermissionsSelector, hasAccessSelector are different for projects. Fix this

export const authSelector = (state: IRootState): IAuthState => state.auth;

export const tokenSelector = createSelector<IRootState, IAuthState, string | null>(authSelector, ({ token }) => token);

export const userSelector = createSelector<IRootState, IAuthState, IAuthUser | null>(authSelector, ({ user }) => user);

export const authStatusSelector = createSelector<IRootState, IAuthState, AuthStatus>(
  authSelector,
  ({ status }) => status
);

export const userIdSelector = createSelector<IRootState, IAuthUser | null, number | undefined>(
  userSelector,
  (user) => user?.id
);

export const usernameSelector = createSelector<IRootState, IAuthUser | null, string | null>(
  userSelector,
  (user) => user?.name || null
);

export const refreshPromiseSelector = createSelector<IRootState, IAuthState, Promise<any> | null>(
  authSelector,
  ({ refreshTokenPromise }) => refreshTokenPromise
);

export const isGuestSelector = createSelector<IRootState, AuthStatus, boolean>(
  authStatusSelector,
  (status) => status === AuthStatus.GUEST
);

export const isLoggedInSelector = createSelector<IRootState, AuthStatus, boolean>(
  authStatusSelector,
  (status) => status === AuthStatus.LOGGED_IN
);

export const isCheckingAuthStatusSelector = createSelector<IRootState, AuthStatus, boolean>(
  authStatusSelector,
  (status) => status === AuthStatus.CHECKING
);

export const langSelector = createSelector<IRootState, IAuthState, string>(authSelector, ({ lang }) => lang || `ru`);

export const hiddenColumnsSelector = (tableName: string) =>
  createSelector<IRootState, IAuthUser | null, string[]>(
    userSelector,
    (user) => user?.hidden_columns?.[tableName] || []
  );

export const tablesPerPageSelector = createSelector<IRootState, IAuthState, number>(
  authSelector,
  (state) => state?.perPage
);

export const userPermissionsSelector = createSelector<IRootState, IAuthUser | null, string[] | null>(
  userSelector,
  (user) => user?.permissions || null
);
