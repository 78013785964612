import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

export interface IWidgetContainerProps {
  children: ReactNode;
  title: string;
}

const WidgetContainer = ({ children, title }: IWidgetContainerProps) => {
  const [t] = useTranslation();

  return (
    <main id="main-container" className="main-container">
      <div className="content content-full">
        <h2 className="content-heading">{t(title)}</h2>
        {children}
      </div>
    </main>
  );
};

export default WidgetContainer;
