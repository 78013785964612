import React from 'react';
import TextField from '@material-ui/core/TextField';
import { TextFieldProps } from '@material-ui/core/TextField/TextField';

type InputProps = {
  id: string;
  className?: string;
  placeholder?: string;
};

export type TextInputProps = InputProps & TextFieldProps;

const TextInput = ({ id, className, placeholder, ...props }: TextInputProps) => {
  return <TextField {...props} className={className} id={id} label={placeholder} />;
};

export default TextInput;
