import React from 'react';
import classNames from 'classnames';

import { Size } from 'utils/enums';

import preloader from 'assets/media/preloader.svg';

export interface IPreloaderProps {
  size?: Size;
  center?: boolean;
  className?: string;
}

const Preloader = ({ size, center, className }: IPreloaderProps) => {
  const _className = classNames(`display-block`, className, {
    'preloader--mini': size === Size.MINI,
    'preloader--little': size === Size.LITTLE,
    'preloader--middle': size === Size.MIDDLE,
    'preloader--big': size === Size.BIG,
    'margin-center': center,
  });

  return <img className={_className} src={preloader} alt="" />;
};

Preloader.defaultProps = {
  size: Size.MIDDLE,
  center: true,
  className: ``,
};

export default Preloader;
