import React, { Fragment, ReactNode, useMemo } from 'react';
import map from 'lodash/map';
import filter from 'lodash/filter';

import InnerTable from 'components/tables/InnerTable';

export interface IFilterColumn {
  id: string;
  desktop: ReactNode;
  mobile: ReactNode;
  hide?: boolean;
}

export interface IFilterColumnsParams {
  columns: IFilterColumn[];
  desktopSize: boolean;
  hiddenColumns: string[];
}

const useFilterColumns = ({ columns, desktopSize, hiddenColumns }: IFilterColumnsParams): ReactNode => {
  const filteredColumns = useMemo<IFilterColumn[]>(
    () =>
      filter(columns, ({ hide = false, ...col }) => {
        if (hide || hiddenColumns.includes(col.id)) {
          return false;
        }

        return desktopSize ? col.desktop : col.mobile;
      }) as IFilterColumn[],
    [desktopSize, columns, hiddenColumns]
  );

  return desktopSize ? (
    <tr>
      {map(filteredColumns, (col) => (
        <Fragment key={col.id}>{col.desktop}</Fragment>
      ))}
    </tr>
  ) : (
    <InnerTable cellClassName="mobile-inner-table">
      {map(filteredColumns, (col) => (
        <Fragment key={col.id}>{col.mobile}</Fragment>
      ))}
    </InnerTable>
  );
};

export default useFilterColumns;
