import get from 'lodash/get';
import { Reducer } from 'react';

import {
  AUTH_CHECK_SUCCEEDED,
  AUTH_ERROR,
  LOGIN_SUCCEEDED,
  LOGOUT_SUCCEEDED,
  REFRESH_TOKEN_FAILED,
  REFRESH_TOKEN_REQUESTED,
  REFRESH_TOKEN_SUCCEEDED,
  SET_LANG,
  SET_TABLES_PER_PAGE,
  SET_TOKEN,
  UPDATE_HIDDEN_COLUMN_SUCCEEDED,
} from 'actionTypes';
import { AuthStatus } from 'utils/enums';
import { DEFAULT_PAGINATION_LIMIT } from 'utils/constants';
import { IAuthState } from 'types/auth';
import { IAction } from 'types/common';

const authInitState: IAuthState = {
  user: null,
  token: null,
  status: AuthStatus.CHECKING,
  error: null,
  refreshTokenPromise: null,
  perPage: DEFAULT_PAGINATION_LIMIT,
};

/**
 * Todo: Оставить свой редюсер для админки. Так как поле permissions, которое приходит в payload по
 * LOGIN_SUCCEEDED и LOGIN_SUCCEEDED, разные. Выставить таску на бек для рефакторинга.
 */
const auth: Reducer<IAuthState, IAction> = (state = authInitState, { type, payload = {} }) => {
  switch (type) {
    case LOGIN_SUCCEEDED:
    case AUTH_CHECK_SUCCEEDED:
      return { ...state, ...payload, status: AuthStatus.LOGGED_IN, error: null };
    case LOGOUT_SUCCEEDED:
      return { ...authInitState, status: AuthStatus.GUEST };
    case AUTH_ERROR:
      return { ...state, ...authInitState, status: AuthStatus.GUEST, error: payload };
    case REFRESH_TOKEN_REQUESTED:
      return { ...state, refreshTokenPromise: payload };
    case REFRESH_TOKEN_FAILED:
      return authInitState;
    case REFRESH_TOKEN_SUCCEEDED:
      return { ...state, token: get(payload, `token`), refreshTokenPromise: null };
    case UPDATE_HIDDEN_COLUMN_SUCCEEDED:
      return {
        ...state,
        user: {
          ...get(state, `user`, {}),
          hidden_columns: {
            ...get(state, `user.hidden_columns`, {}),
            [payload.name]: payload.columns,
          },
        },
      };
    case SET_TOKEN:
      return { ...state, token: payload };
    case SET_LANG:
      return { ...state, lang: payload };
    case SET_TABLES_PER_PAGE:
      return { ...state, perPage: payload };
    default:
      return state;
  }
};

export default auth;
