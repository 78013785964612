import React, { ComponentType } from 'react';
import map from 'lodash/map';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { GetRouteTabs } from 'types/widgets';

interface IProps {
  permissions: string[] | null;
  routeName: string;
  ReactNavLink: ComponentType<any>;

  routeTabs?: GetRouteTabs;
}

const RouteTabs = ({ routeTabs, permissions, routeName, ReactNavLink }: IProps) => {
  const [t] = useTranslation();

  return (
    <>
      {routeTabs && (
        <ul className="nav nav-tabs nav-tabs-block">
          {map(
            routeTabs(permissions),
            ({ title, path, hasAccess }, key) =>
              hasAccess && (
                <li key={key} className="nav-item">
                  <ReactNavLink
                    className={classNames(`nav-link`, {
                      // active: routeName === path,
                      active: path.startsWith(routeName),
                    })}
                    to={path}
                  >
                    {t(title)}
                  </ReactNavLink>
                </li>
              )
          )}
        </ul>
      )}
    </>
  );
};

export default RouteTabs;
