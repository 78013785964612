import React, { ComponentType, ReactNode, useRef, useState } from 'react';
import map from 'lodash/map';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useTranslation } from 'react-i18next';
import useClickAway from 'react-use/lib/useClickAway';

import useDesktopSize from 'hooks/useDesktopSize';
import useIsTouchDevice from 'hooks/useIsTouchDevice';
import { INavListData } from 'types/nav';

import Sidebar from 'components/header/Sidebar';
import { useMedia } from 'react-use';

export interface INavItemProps extends INavListData {
  ReactNavLink: ComponentType<any>;
}

const NavItem = ({ title, id, path, icon, hasAccess, onClick, children, ReactNavLink }: INavItemProps) => {
  const [opened, setOpened] = useState(false);
  const listRef = useRef(null);

  useClickAway(listRef, () => {
    setOpened(false);
  });

  const [t] = useTranslation();

  const isTouchDevice = useIsTouchDevice();

  if (!hasAccess) {
    return null;
  }

  let content = (
    <>
      {icon && <i className={icon} />}
      {t(title)}
    </>
  );

  let innerList;
  if (children) {
    const showListForTouchScreen = isTouchDevice && opened;
    const showListForDesktop = !isTouchDevice;
    if (showListForTouchScreen) {
      innerList = (
        <ul ref={listRef} className="header__secondary-menu d-block">
          {map(children, (item, key) => (
            <NavItem {...item} key={key} ReactNavLink={ReactNavLink} />
          ))}
        </ul>
      );
    } else if (showListForDesktop) {
      innerList = (
        <ul className="header__secondary-menu">
          {map(children, (item, key) => (
            <NavItem {...item} key={key} ReactNavLink={ReactNavLink} />
          ))}
        </ul>
      );
    }
  }

  const switcherForTouchScreen = isTouchDevice && children;
  if (switcherForTouchScreen) {
    content = (
      <button onClick={() => setOpened(!opened)} className="header-primary-btn" id={id}>
        {content}
      </button>
    );
  } else if (path) {
    content = (
      <ReactNavLink to={path} id={id}>
        {content}
      </ReactNavLink>
    );
  } else if (onClick) {
    content = (
      <button onClick={onClick} className="header-primary-btn" id={id}>
        {content}
      </button>
    );
  }

  return (
    <li>
      {content}
      {innerList}
    </li>
  );
};

export interface IHeaderProps {
  logo: ReactNode;
  list: INavListData[];
  loadingBar: ReactNode;
  ReactNavLink: ComponentType<any>;

  notificationSlot?: ReactNode;
}

const Header = ({ logo, list, loadingBar, ReactNavLink, notificationSlot }: IHeaderProps) => {
  const [displaySidebar, setDisplaySidebar] = useState(false);
  const isDesktop = useMedia(`(min-width: 992px)`);

  const desktopSize = useDesktopSize();

  return (
    <>
      <header id="page-header">
        {loadingBar}
        <div className="content-header">
          <div className="content-header-item">{logo}</div>

          <div className="content-header-section d-none d-lg-block">
            <div className="content-header-section-wrapper">
              <ul className="nav-main-header">
                {map(list, (item, key) => (
                  <NavItem {...item} key={key} ReactNavLink={ReactNavLink} />
                ))}
              </ul>
              {isDesktop && notificationSlot}
            </div>
          </div>
          <div className="header-wrapper d-lg-none">
            {!isDesktop && notificationSlot}
            <div className="content-header-section">
              <button
                type="button"
                className="btn btn-circle btn-dual-secondary d-lg-none"
                onClick={() => setDisplaySidebar(true)}
              >
                <i className="fa fa-navicon" />
              </button>
            </div>
          </div>
        </div>
        <div id="page-header-loader" className="overlay-header bg-primary">
          <div className="content-header content-header-fullrow text-center">
            <div className="content-header-item">
              <i className="fa fa-sun-o fa-spin text-white" />
            </div>
          </div>
        </div>
      </header>
      <TransitionGroup>
        {!desktopSize && displaySidebar && (
          <CSSTransition classNames="sidebar-transition" timeout={260}>
            <Sidebar hideSidebar={() => setDisplaySidebar(false)} list={list} logo={logo} ReactNavLink={ReactNavLink} />
          </CSSTransition>
        )}
      </TransitionGroup>
    </>
  );
};

export default Header;
