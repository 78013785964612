import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { IModalProps } from 'types/common';
import { Size } from 'utils/enums';

import ModalElement from 'components/modals/ModalElement';
import Preloader from 'components/base/Preloader';

export interface IConfirmationModalProps extends IModalProps {
  confirmText: string;
  onConfirm: () => void;
  content: string;
  submitting?: boolean;

  confirmClassName?: string;
}

const ConfirmationModal = ({
  actionCreators,
  confirmText,
  onConfirm,
  confirmClassName,
  content,
  dispatch,
  submitting,
  ...props
}: IConfirmationModalProps) => {
  const [t] = useTranslation();

  const onClose = useCallback(() => dispatch(actionCreators.hideModal()), [dispatch, actionCreators]);

  const footer = (
    <>
      <button className="btn btn-alt-secondary mr-10" onClick={onClose} disabled={submitting}>
        {t(`common.cancel`)}
      </button>
      <button className={classNames(`btn`, confirmClassName)} onClick={onConfirm} disabled={submitting}>
        <div className="d-flex">
          {t(confirmText)}
          {submitting && <div className="ml-1">{<Preloader size={Size.MINI} />}</div>}
        </div>
      </button>
    </>
  );

  return (
    <ModalElement
      {...props}
      footer={footer}
      dispatch={dispatch}
      content={t(content)}
      actionCreators={actionCreators}
      footerClassName="modal__footer--confirmation"
    />
  );
};

ConfirmationModal.defaultProps = {
  confirmClassName: `btn-alt-success`,
};

export default ConfirmationModal;
