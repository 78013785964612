// Widgets

export const WIDGET_LIST_REQUESTED = `WIDGET_LIST_REQUESTED`;
export const WIDGET_LIST_SUCCEEDED = `WIDGET_LIST_SUCCEEDED`;
export const WIDGET_LIST_FAILED = `WIDGET_LIST_FAILED`;
export const WIDGET_LIST_RESET = `WIDGET_LIST_RESET`;

export const WIDGET_ITEM_REQUESTED = `WIDGET_ITEM_REQUESTED`;
export const WIDGET_ITEM_SUCCEEDED = `WIDGET_ITEM_SUCCEEDED`;
export const WIDGET_ITEM_FAILED = `WIDGET_ITEM_FAILED`;
export const WIDGET_ITEM_RESET = `WIDGET_ITEM_RESET`;

export const WIDGET_SET_ITEM = `WIDGET_SET_ITEM`;

export const WIDGET_SET_MODAL = `WIDGET_SET_MODAL`;
export const WIDGET_RESET_MODAL = `WIDGET_RESET_MODAL`;

export const WIDGET_SET_SUBMITTING_STATUS = `WIDGET_SET_SUBMITTING_STATUS`;

export const WIDGET_SET_ERROR = `WIDGET_SET_ERROR`;
export const WIDGET_RESET_ERROR = `WIDGET_RESET_ERROR`;

export const WIDGET_SET_MODAL_ERROR = `WIDGET_SET_MODAL_ERROR`;
export const WIDGET_RESET_MODAL_ERROR = `WIDGET_RESET_MODAL_ERROR`;

export const WIDGET_CREATE_REQUESTED = `WIDGET_CREATE_REQUESTED`;
export const WIDGET_CREATE_SUCCEEDED = `WIDGET_CREATE_SUCCEEDED`;

export const WIDGET_REMOVE_REQUESTED = `WIDGET_REMOVE_REQUESTED`;
export const WIDGET_REMOVE_SUCCEEDED = `WIDGET_REMOVE_SUCCEEDED`;
export const WIDGET_SYNC_REMOVE = `WIDGET_SYNC_REMOVE`;
export const WIDGET_REMOVE_MULTIPLE_ITEMS_REQUESTED = `WIDGET_REMOVE_MULTIPLE_ITEMS_REQUESTED`;
export const WIDGET_REMOVE_MULTIPLE_ITEMS_SUCCEEDED = `WIDGET_REMOVE_MULTIPLE_ITEMS_SUCCEEDED`;

export const WIDGET_LIST_ITEM_UPDATE_REQUESTED = `WIDGET_LIST_ITEM_UPDATE_REQUESTED`;
export const WIDGET_LIST_ITEM_UPDATE_SUCCEEDED = `WIDGET_LIST_ITEM_UPDATE_SUCCEEDED`;

export const WIDGET_ITEM_UPDATE_REQUESTED = `WIDGET_ITEM_UPDATE_REQUESTED`;
export const WIDGET_ITEM_UPDATE_SUCCEEDED = `WIDGET_ITEM_UPDATE_SUCCEEDED`;

export const WIDGET_SET_PAGE = `WIDGET_SET_PAGE`;

export const WIDGET_SET_ANY_DATA = `WIDGET_SET_ANY_DATA`;

export const WIDGET_SELECT_ITEM = `WIDGET_SELECT_ITEM`;
export const WIDGET_UNSELECT_ITEM = `WIDGET_UNSELECT_ITEM`;
export const WIDGET_RESET_SELECT_LIST = `WIDGET_RESET_SELECT_LIST`;

// Filters

export const SUBMIT_FILTERS = `SUBMIT_FILTERS`;

// Auth

export const LOGIN_REQUESTED = `LOGIN_REQUESTED`;
export const LOGIN_SUCCEEDED = `LOGIN_SUCCEEDED`;

export const LOGOUT_REQUESTED = `LOGOUT_REQUESTED`;
export const LOGOUT_SUCCEEDED = `LOGOUT_SUCCEEDED`;

export const AUTH_ERROR = `AUTH_ERROR`;

export const AUTH_CHECK_REQUESTED = `AUTH_CHECK_REQUESTED`;
export const AUTH_CHECK_SUCCEEDED = `AUTH_CHECK_SUCCEEDED`;

export const SET_TOKEN = `SET_TOKEN`;

export const REFRESH_TOKEN_REQUESTED = `REFRESH_TOKEN_REQUESTED`;
export const REFRESH_TOKEN_SUCCEEDED = `REFRESH_TOKEN_SUCCEEDED`;
export const REFRESH_TOKEN_FAILED = `REFRESH_TOKEN_FAILED`;

export const SET_LANG = `SET_LANG`;

export const SET_TABLES_PER_PAGE = `SET_TABLES_PER_PAGE`;

// Table control

export const UPDATE_HIDDEN_COLUMN_REQUESTED = `UPDATE_HIDDEN_COLUMN_REQUESTED`;
export const UPDATE_HIDDEN_COLUMN_SUCCEEDED = `UPDATE_HIDDEN_COLUMN_SUCCEEDED`;

// Form

export const SET_UNUSED_FIELDS = `SET_UNUSED_FIELDS`;

// Config

export const INIT_APP_REQUESTED = `INIT_APP_REQUESTED`;
export const INIT_APP_SUCCEEDED = `INIT_APP_SUCCEEDED`;
