import React, { ReactNode } from 'react';

export interface IInnerTableProps {
  children: ReactNode;
  cellClassName: string;
}

const InnerTable = ({ children, cellClassName }: IInnerTableProps) => (
  <tr>
    <td className="no-padding no-border">
      <div className={cellClassName}>
        <table className="table inner-table">
          <tbody>{children}</tbody>
        </table>
      </div>
    </td>
  </tr>
);

InnerTable.defaultProps = {
  children: null,
  cellClassName: ``,
};

export default InnerTable;
