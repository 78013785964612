import React, { useCallback } from 'react';
import TimeField from 'react-simple-timefield';
import get from 'lodash/get';
import { WrappedFieldInputProps } from 'redux-form/lib/Field';

const TimeInput = (props: any) => <input {...props} className="form-control" />;

export interface IRangeTimeProps {
  input: WrappedFieldInputProps;
  showSeconds?: boolean;
}

const RangeTime = ({ input, showSeconds }: IRangeTimeProps) => {
  const onChange = get(input, `onChange`);
  const fromTime = get(input, `value.fromTime`);
  const toTime = get(input, `value.toTime`);

  const onDateChange = useCallback(
    (val: string, type: `fromTime` | `toTime`) => {
      const timeRange = { fromTime, toTime };
      timeRange[type] = val;
      onChange(timeRange);
    },
    [fromTime, toTime]
  );

  return (
    <div className="timerange__wrap">
      <span className="timerange__item">
        <TimeField
          onChange={(event) => onDateChange(event.target.value, `fromTime`)}
          input={<TimeInput showSeconds={showSeconds} />}
          value={fromTime}
          showSeconds={showSeconds}
        />
      </span>
      <span className="timerange__item ml-10">
        <TimeField
          onChange={(event) => onDateChange(event.target.value, `toTime`)}
          input={<TimeInput showSeconds={showSeconds} />}
          value={toTime}
          showSeconds={showSeconds}
        />
      </span>
    </div>
  );
};

export default RangeTime;
