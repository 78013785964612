import { reducer as formReducer } from 'redux-form';
import get from 'lodash/get';

import { SET_UNUSED_FIELDS } from 'actionTypes';
import { IAction } from 'types/common';
import { IFormActionMeta, IFormsState } from 'types/form';

const reducer = (state: IFormsState, action: IAction<any, IFormActionMeta>) => {
  const formName = get(action, `meta.form`);
  const formData = get(state, `${formName}`, {});
  const unusedFields = get(action, `payload`);

  switch (action.type) {
    case SET_UNUSED_FIELDS:
      return {
        ...state,
        [formName]: {
          ...formData,
          unused: unusedFields,
        },
      };
    default:
      return formReducer(state, action);
  }
};

export default reducer;
