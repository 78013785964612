import React, { MutableRefObject } from 'react';
import { Multiselect } from 'react-widgets';
import { useTranslation } from 'react-i18next';
import { MultiselectProps } from 'react-widgets/lib/Multiselect';

import useDropdownOpeningToUp from 'hooks/form/useDropdownOpeningToUp';
import { ISelectData } from 'types/form';
import useDropdownTranslator from 'hooks/form/useDropdownTranslator';

export interface IMultiSelectOwnProps extends MultiselectProps {
  data?: ISelectData[];
  className?: string;
  translateKeys?: boolean;
  isModalField?: boolean;
  fieldWrapperRef?: MutableRefObject<any>;
}

const MultiSelect = ({
  data,
  className,
  translateKeys,
  fieldWrapperRef,
  isModalField,
  ...props
}: IMultiSelectOwnProps) => {
  const [t] = useTranslation();

  const list = useDropdownTranslator(data as ISelectData[], translateKeys);

  const { doMeasure, selectRef, openUp } = useDropdownOpeningToUp({
    fieldWrapperRef,
    isModalField,
  });

  return (
    <div ref={selectRef}>
      <Multiselect
        {...props}
        value={!props.value ? [] : props.value}
        data={list}
        valueField="value"
        textField="text"
        dropUp={openUp}
        messages={{
          open: ``,
          emptyList: t(`common.listIsEmpty`),
          emptyFilter: t(`common.filterReturnedNoResults`),
        }}
        // @ts-ignore
        onDrop={(e: any) => e.preventDefault()}
        onBlur={(e: any) => e.preventDefault()}
        containerClassName={className}
        onToggle={doMeasure}
      />
    </div>
  );
};

MultiSelect.defaultProps = {
  data: [],
  className: ``,
  translateKeys: false,
};

export default MultiSelect;
