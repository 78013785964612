import { createSelector } from 'reselect';
import find from 'lodash/find';
import get from 'lodash/get';

import { IWidgetItem, IWidgetModal, IWidgetsState } from 'types/widgets';
import { IRootState } from 'types/common';
import { widgetDefaultState } from 'reducers/widgets';

export const widgetSelector =
  <State = IWidgetsState>(widget: string) =>
  (state: IRootState): State => {
    return (state && state.widgets && state.widgets[widget] ? state.widgets[widget] : widgetDefaultState) as any;
  };

export const widgetModalSelector = <Payload = any>(widget: string) => {
  return createSelector<IRootState, IWidgetsState, IWidgetModal<Payload> | null>(
    widgetSelector(widget),
    ({ modal }) => modal || null
  );
};

export const widgetItemSelector = <Item extends IWidgetItem = any>(widget: string) =>
  createSelector<IRootState, IWidgetsState, Item | null>(widgetSelector(widget), ({ item }) => item || null);

export const widgetItemIdSelector = <Item extends IWidgetItem = any>(widget: string) =>
  createSelector<IRootState, Item | null, number | string | undefined>(
    widgetItemSelector<Item>(widget),
    (item) => item?.id
  );

export const widgetPageSelector = (widget: string) =>
  createSelector<IRootState, IWidgetsState, number>(widgetSelector(widget), (state) => state?.page);

export const widgetItemLoadingSelector = (widget: string) =>
  createSelector<IRootState, IWidgetsState, boolean>(widgetSelector(widget), (state) => state?.itemLoading);

export const widgetSubmittingSelector = (widget: string) =>
  createSelector<IRootState, IWidgetsState, boolean>(widgetSelector(widget), (state) => state?.submitting);

export const widgetListSelector = <ListItem = any>(widget: string) =>
  createSelector<IRootState, IWidgetsState, ListItem[] | null>(widgetSelector(widget), (state) => state?.items || null);

export const widgetListLoadingSelector = (widget: string) =>
  createSelector<IRootState, IWidgetsState, boolean>(widgetSelector(widget), (state) => state?.listLoading);

export interface IWidgetListItemParams {
  path?: string;
  value: any;
}

export const widgetListItemSelector = <ListItem = any>({ path = `id`, value }: IWidgetListItemParams) => {
  return (widget: string) => {
    return createSelector<IRootState, ListItem[] | null, ListItem | undefined>(widgetListSelector(widget), (list) =>
      find(list, (item) => get(item, path) === value)
    );
  };
};

export const widgetItemPathSelector = <Item extends IWidgetItem = any, Result = any>(widget: string, path: string) => {
  return createSelector<IRootState, Item | null, Result | undefined>(widgetItemSelector<Item>(widget), (item) =>
    get(item, path)
  );
};

export const widgetFieldSelector = <Result = any>(widget: string, path: string) => {
  return createSelector<IRootState, IWidgetsState, Result>(widgetSelector(widget), (data) => get(data, path));
};

export const widgetModalErrorSelector = (widget: string) =>
  createSelector<IRootState, IWidgetsState, string | null>(widgetSelector(widget), (state) => state?.modalError);

export const widgetSelectedItemsSelector = (widget: string) =>
  createSelector<IRootState, IWidgetsState, any>(widgetSelector(widget), (state) => state?.selectedItems);

export const paginationLoadingSelector = (widget: string) =>
  createSelector<IRootState, IWidgetsState, boolean>(widgetSelector(widget), (state) => state?.paginationLoading);
